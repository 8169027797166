.article-teas {
  display: block;
  transition: var(--duration-sm);

  &:hover {
    color: var(--clr-primary);
  }

  &__wrap {
    display: grid;
    grid-template-columns: 100%;
    gap: var(--gap-lg);
  }

  &__cover {
    img {
      border-radius: var(--radius-xs);
    }
  }

  &__date {
    margin-bottom: calc(var(--gap-xs) * -1);
  }

  &__title {
    font-size: var(--font-size-sm);
    font-weight: 600;
  }
}
