.cookie-box {
  position: fixed;
  z-index: 10200;
  left: var(--gap-xs);
  margin: 0 auto;
  background-color: var(--clr-white);
  border: 1px solid var(--clr-black);
  color: var(--clr-text);
  padding: var(--gap-sm);
  border-radius: var(--radius-md);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--gap-md);
  font-size: var(--font-size-sm);
  box-shadow: 0 8px 8px -4px hsl(var(--clr-black-hsl) / 32%);
  transition: transform var(--duration-md) cubic-bezier(0.68, -0.55, 0.25, 1.35);
  transform: translateX(0);

  &.is-hide {
    transform: translateX(calc(-100% - 16px));
  }

  a {
    text-decoration: underline;
  }

  @include media-down(sm) {
    right: var(--gap-xs);
  }

  @include media-up(sm) {
    width: max-content;
  }

  @include media-down(md) {
    bottom: calc(var(--gap-6xl) + var(--gap-xs));
  }

  @include media-up(md) {
    bottom: var(--gap-xs);
  }
}
