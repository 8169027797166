.form-group,
.group {
  --gap: var(--gap-xs);
  display: flex;
  flex-direction: column;
  gap: var(--gap);

  &--line {
    flex-direction: row;
    column-gap: var(--gap-xs);
    align-items: center;

    @include media-down(sm) {
      flex-wrap: wrap;
    }
  }

  &--radio {
    --gap: var(--gap-xl);
  }

  &:not(:last-child) {
    margin-bottom: var(--gap-xs);
  }

  &:not(.has-error) + .form-caption--error {
    display: none;
  }

  &.has-error {
    --color-border: var(--clr-error);
    --color-bg: hsl(var(--clr-error-hsl) / 8%);

    .text--error,
    .form-caption--error {
      display: block;
    }
  }
}

.form-label,
.label {
  font-size: var(--font-size-sm);

  &--md {
    @include media-up(md) {
      font-size: var(--font-size-base);
    }
  }

  &--strong {
    font-weight: var(--font-weight-semibold);
  }

  &--right {
    text-align: right;
  }

  &--gray {
    color: var(--clr-gray);
  }

  em {
    font-style: normal;
    color: var(--clr-gray);
  }
}

.value {
  font-weight: var(--font-weight-semibold);

  &--sm {
    font-size: var(--font-size-sm);
  }
}

.form-search {
  &__main {
    position: relative;
  }
}

.form-caption {
  font-size: var(--font-size, var(--font-size-sm));
  color: var(--color, var(--clr-black));

  &--xs {
    --font-size: var(--font-size-xs);
  }

  &--error {
    display: none;
    font-weight: var(--font-weight-medium);
    --color: var(--clr-error);
  }

  strong {
    font-weight: var(--font-weight-semibold);
  }
}

.smart-result {
  position: absolute;
  width: 100%;
  border: 1px solid var(--clr-border-input-focus);
  border-radius: var(--radius-md);
  margin-top: -1px;
  padding: var(--gap-xs) 0;
  max-height: 160px;
  @include scrollbar-v;

  &[open] {
    z-index: 80;
  }

  &__list {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &__item {
    display: block;
    cursor: pointer;
    width: 100%;
    text-align: left;
    font-size: var(--font-size-sm);
    font-weight: var(--font-weight-medium);
    line-height: var(--gap-md);
    padding: var(--gap-sm) var(--gap-md);
    transition: var(--duration-md);

    &:hover,
    &.is-active {
      background-color: var(--clr-bg-light);
    }
  }
}

.quick-tips {
  &__list {
    padding: 0;
    margin: var(--gap-sm) 0 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    column-gap: var(--gap-md);
    row-gap: var(--gap-xs);
  }

  &__btn {
    display: block;
    font-size: var(--font-size-xs);
    font-weight: var(--font-weight-medium);
    border-bottom: 1px dashed var(--clr-gray);
    transition: border-color var(--duration-sm);

    &:hover {
      border-color: var(--clr-black);
    }
  }
}
