.prod-weak {
  @media (hover: hover) {
    --opacity-extra: 0;
    --translateY-extra: calc(var(--gap-xs) * -1);

    &:hover {
      --opacity-extra: 1;
      --translateY-extra: 0;
    }
  }

  &--center {
    text-align: center;

    img {
      margin: auto;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }

  &__main {
    position: relative;
  }

  &__cover {
    position: relative;
    width: 100%;
    max-width: 180px;
    aspect-ratio: 1/1;
    overflow: hidden;

    picture:not(.lazy):not(.img--loaded) {
      right: unset !important;
    }

    img {
      position: absolute;
      max-width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__select {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;

    .switch {
      &__main {
        position: static;

        &::before {
          position: absolute;
          content: '';
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
        }
      }
    }
  }

  &__title {
    font-weight: normal;
    font-size: var(--font-size-sm);
    margin: var(--gap-xl) 0 var(--gap-2xs);
  }

  &__extra {
    opacity: var(--opacity-extra, 1);
    transform: translateY(var(--translateY-extra));
    margin-top: var(--gap-xs);
    transition: opacity var(--duration-sm), transform var(--duration-sm);
  }
}
