.box {
  padding: var(--side-gap, 0);
  position: relative;

  &--bordered {
    --side-gap: clamp(var(--gap-lg), 4vw, var(--gap-2xl));
    border: 1px solid var(--clr-border-input);
    border-radius: var(--radius-sm);
  }

  &--inner-shadow {
    box-shadow: inset 0 0 var(--gap-4xl) hsl(var(--clr-primary-hsl) / .08);
    overflow: hidden;
  }

  &--cover-shadow {
    --cover-size: clamp(148px, 20vw, 180px);

    @include media-up(md) {
      position: relative;

      &::before {
        position: absolute;
        content: '';
        width: var(--cover-size);
        height: 62px;
        background-image: url('../img/cover-shadow-favorite.svg');
        background-repeat: no-repeat;
        background-position: 0 0;
        background-size: contain;
        bottom: 0;
      }
    }
  }

  &__wrap {
    display: flex;
    flex-direction: column;
    gap: var(--gap-xl);

    &--favorite {
      &::before,
      &::after {
        position: absolute;
        content: '';
        background-repeat: no-repeat;
        background-position: 0 0;
        z-index: 0;
        pointer-events: none;
      }

      &::before {
        width: 62px;
        height: 248px;
        background-image: url('../img/cover-favorite-left.svg');
        left: calc(var(--gap-sm) * -1);
        top: calc(var(--gap-4xl) * 2);
      }

      &::after {
        width: 72px;
        height: 252px;
        background-image: url('../img/cover-favorite-right.svg');
        right: calc(var(--gap-2xl) * -1);
        top: var(--gap-lg);
      }
    }
  }

  &__title {
    --font-size: clamp(var(--font-size-box-title-min), 3.2vw, var(--font-size-box-title-max));
    font-weight: var(--font-weight-semibold);
    font-size: var(--font-size);
    line-height: 1;

    &--md {
      --font-size: clamp(var(--font-size-xl), 4vw, var(--font-size-2xl));
    }

    &--primary {
      color: var(--clr-primary);
    }

    &--best {
      position: relative;

      &::before {
        position: absolute;
        content: '';
        width: 1em;
        aspect-ratio: 1/1;
        background-color: var(--clr-primary);
        border-radius: 50%;
        clip-path: inset(0 0 0 50%);
        left: calc(calc(var(--side-gap) + .5em) * -1);
      }
    }
  }

  &__blank {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--gap-md);
    margin-top: var(--gap-md);
    margin-bottom: var(--gap-md);
  }

  &__bottom {
    position: relative;
    z-index: 2;
  }
}
