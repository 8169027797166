@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-VariableFont_wght.woff2') format('woff2 supports variations'),
  url('../fonts/Montserrat-VariableFont_wght.woff2') format('woff2-variations');
  font-weight: 100 900;
  font-stretch: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Comfortaa';
  src: url('../fonts/Comfortaa-VariableFont_wght.woff2') format('woff2 supports variations'),
  url('../fonts/Comfortaa-VariableFont_wght.woff2') format('woff2-variations');
  font-weight: 300 700;
  font-stretch: normal;
  font-style: normal;
  font-display: swap;
}
