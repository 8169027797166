.status {
  display: inline-flex;
  align-items: center;
  gap: var(--gap);
  vertical-align: baseline;
  color: var(--color);

  &--bg {
    background-color: var(--clr-bg-light);
    border-radius: var(--radius-sm);
    padding: var(--gap) var(--gap-md);
  }

  &--sm {
    --gap: var(--gap-xs);
    --font-size: var(--font-size-sm);
  }

  &--success {
    --color: var(--clr-success);
  }

  &--error {
    --color: var(--clr-error-dark);
  }

  &__label {
    font-size: var(--font-size);
    font-weight: var(--font-weight-semibold);
  }
}
