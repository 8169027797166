.search-result {
  --h-gap: var(--gap-lg);
  --v-gap: var(--gap-md);
  background-color: var(--clr-white);
  position: absolute;
  margin: var(--gap-sm) 0 0;
  z-index: 8;
  padding: 0;
  font-size: var(--font-size-sm);
  border-color: var(--clr-border-input);
  border-style: solid;

  @include media-down(md) {
    left: 0;
    right: 0;
    width: 100%;
    border-width: 1px 0 1px 0;
  }

  @include media-up(md) {
    right: 0;
    width: 100%;
    border-width: 1px;
  }

  &--similar {
    left: 0 !important;
    right: 0 !important;
    width: 100% !important;
    margin: calc(-1 * var(--gap-xs)) 0 0;
  }

  &__wrap {
    max-height: 520px;
    @include scrollbar-v;
  }

  &__block {
    &:not(:last-child) {
      border-bottom: 1px solid var(--clr-border-input);
    }
  }

  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__item {
    &[data-selected] {
      .result-item {
        background-color: var(--clr-bg-light);
      }
    }
  }

  &__item + &__item {
    border-top: 1px solid var(--clr-border-input);
  }

  &__title {
    margin: var(--gap-xs) 0 var(--gap-2xs) var(--h-gap);
    font-weight: normal;
    font-size: var(--font-size-sm);
  }

  &__not-found {
    padding: var(--gap-xl) var(--h-gap);
    color: var(--clr-gray);
    font-weight: var(--font-weight-medium);
  }
}

.result-item {
  --cover-size: var(--photo-sm);
  display: grid;
  grid-template-columns: var(--cover-size) 1fr;
  gap: var(--gap-lg);
  align-items: center;
  padding: var(--v-gap) var(--h-gap);
  transition: var(--duration-sm);

  &:hover {
    --color: var(--clr-primary);
  }

  &:focus {
    outline: none;
    background-color: var(--clr-bg-light);
  }

  @include media-up(md) {
    align-items: center;
  }

  &__cover {
    aspect-ratio: 1/1;
    background-color: var(--clr-white);
    outline: 2px solid var(--clr-white);
    border-radius: var(--radius-sm);
    @include img-center;
  }

  &__main {
    flex-grow: 1;
    display: flex;
    align-items: center;
    column-gap: var(--gap-md);
    row-gap: var(--gap-2xs);

    @include media-down(md) {
      flex-wrap: wrap;
    }
  }

  &__info {
    transition: var(--duration-sm);
    color: var(--color, var(--clr-black));
  }

  &__extra {
    align-items: center;
    gap: var(--gap-xl);

    @include media-down(md) {
      flex-basis: 100%;
      display: flex;
    }

    @include media-up(md) {
      margin-left: auto;
      display: grid;
      grid-template-columns: auto 120px 132px;
    }
  }

  &__sex {
    @include media-down(md) {
      display: none;
    }
  }

  &__prices {
    grid-column: 3/4;
    text-align: right;
    font-weight: bold;
    white-space: nowrap;

    @include media-down(sm) {
      margin-left: auto;
    }
  }

  &__stats {
    grid-column: 1/4;
    text-align: right;
  }
}
