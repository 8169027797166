.badge {
  display: flex;
  flex-direction: var(--badge-flex-direction, row);
  align-items: center;

  &--sale {
    --bg-color: var(--clr-primary);
  }

  &--new {
    --bg-color: var(--clr-blue);
  }

  &--hit {
    --bg-color: var(--clr-orange);
  }

  &__label {
    font-size: var(--font-size-xs);
    font-weight: 500;
    color: var(--clr-white);
    background-color: var(--bg-color);
    // ниже фикс, чтобы во время анимаций и в других ситуациях между лейблом и хвостом не было просветов
    box-shadow: 3px 0 0 var(--bg-color);
    line-height: var(--icon-lg);
    padding-right: var(--badge-label-padding-right, var(--gap-xs));
    padding-left: var(--badge-label-padding-left, var(--gap-sm));
    border-radius: var(--badge-label-border-radius, 2px 0 0 2px);

    i {
      font-style: normal;

      @include media-down(sm) {
        display: none;
      }
    }
  }

  &__flag {
    color: var(--bg-color);
    margin: var(--badge-flag-margin, 0 0 0 -1px);
    transform: var(--badge-flag-transform, scale(1, 1));
  }
}
