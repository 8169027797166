.brand-teas {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 180px;
  height: 80px;
  margin: auto;
  border: var(--gap-md) solid transparent;
  background-color: var(--clr-white);
  transition: var(--duration-sm);
  position: relative;
  overflow: hidden;

  img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    filter: grayscale(var(--img-grayscale, 100%));
  }

  &:hover {
    box-shadow: 0 var(--gap-xs) var(--gap-xl) rgba(0, 0, 0, .05);
    --img-grayscale: 0;
  }
}
