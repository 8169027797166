:root {
  --clr-white-h: 0deg;
  --clr-white-s: 100%;
  --clr-white-l: 100%;

  --clr-white-hsl: var(--clr-white-h) var(--clr-white-s) var(--clr-white-l);
  --clr-white: hsl(var(--clr-white-hsl));

  --clr-primary-h: 345deg;
  --clr-primary-s: 85%;
  --clr-primary-l: 73%;

  --clr-primary-hsl: var(--clr-primary-h) var(--clr-primary-s) var(--clr-primary-l);
  --clr-primary: hsl(var(--clr-primary-hsl));
  --clr-primary-bg: hsl(var(--clr-primary-h) 100% 96%);
  --clr-primary-contrast: hsl(var(--clr-primary-h) 88% 64%);

  --clr-black-h: 234deg;
  --clr-black-s: 12%;
  --clr-black-l: 16%;

  --clr-black-hsl: var(--clr-black-h) var(--clr-black-s) var(--clr-black-l);
  --clr-black: hsl(var(--clr-black-hsl));

  --clr-gray-h: 205deg;
  --clr-gray-s: 16%;
  --clr-gray-l: 48%;

  --clr-gray: hsl(
      var(--clr-gray-h) var(--clr-gray-s) var(--clr-gray-l)
  );

  --clr-gray-dark-h: 238deg;
  --clr-gray-dark-s: 13%;
  --clr-gray-dark-l: 61%;

  --clr-gray-dark: hsl(
      var(--clr-gray-dark-h) var(--clr-gray-dark-s) var(--clr-gray-dark-l)
  );

  --clr-gray-light: hsl(
      var(--clr-gray-h) var(--clr-gray-s) 72%
  );

  --clr-blue-h: 227deg;
  --clr-blue-s: 100%;
  --clr-blue-l: 77%;

  --clr-blue: hsl(
      var(--clr-blue-h) var(--clr-blue-s) var(--clr-blue-l)
  );

  --clr-orange-h: 35deg;
  --clr-orange-s: 90%;
  --clr-orange-l: 70%;

  --clr-orange: hsl(
      var(--clr-orange-h) var(--clr-orange-s) var(--clr-orange-l)
  );

  --clr-success-h: 126deg;
  --clr-success-s: 33%;
  --clr-success-l: 45%;

  --clr-success: hsl(
      var(--clr-success-h) var(--clr-success-s) var(--clr-success-l)
  );

  --clr-error-h: 335deg;
  --clr-error-s: 61%;
  --clr-error-l: 73%;

  --clr-error-hsl: var(--clr-error-h) var(--clr-error-s) var(--clr-error-l);
  --clr-error: hsl(var(--clr-error-hsl));
  --clr-error-dark: #C97B8E;

  --clr-placeholder: #9DABB5;
  --clr-border: #DADAE9;
  --clr-border-light: #F3F3F9;
  --clr-border-input: #E5EAF2;
  --clr-border-input-hover: #CED6E4;
  --clr-border-input-focus: var(--clr-gray);
  --clr-bg-light: #F8F9FB;
  --clr-bg-light2: #EFF2F6;
}
