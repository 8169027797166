.gift-block {
  display: flex;
  flex-direction: column;
  gap: var(--gap-2xl);

  &__list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    gap: clamp(var(--gap-lg), 4vw, var(--gap-3xl));
  }
}

.gift-item {
  height: 100%;
  border: 1px solid var(--clr-border-input);
  padding: clamp(var(--gap-xl), 4vw, var(--gap-3xl)) var(--gap-xl) var(--gap-xl);

  &--extra {
    background-color: var(--clr-primary);
    color: var(--clr-white);
    display: flex;
    gap: var(--gap-md);
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  &__cover {
    margin-left: calc(var(--gap-xl) * -1);
    margin-right: calc(var(--gap-xl) * -1);
    margin-bottom: clamp(var(--gap-xl), 4vw, var(--gap-3xl));
  }

  &__title {
    font-weight: var(--font-weight-semibold);
    font-size: var(--font-size-lg);
  }

  &__text {
    font-weight: var(--font-weight-medium);
    line-height: 140%;
  }
}