.more-box {
  --clr-marker: var(--clr-black);
  --rotate-marker: 0deg;
  --line-height-title: var(--gap-lg);

  &[open] {
    --clr-marker: var(--clr-primary);
    --rotate-marker: 180deg;
  }

  &--bg {
    background-color: var(--clr-bg-light);
  }

  &__top,
  &__main {
    @include media-down(xl) {
      padding-left: var(--gap-sm);
      padding-right: var(--gap-sm);
      padding-bottom: var(--gap-sm);
    }

    @include media-up(xl) {
      padding-left: var(--gap-md);
      padding-right: var(--gap-md);
      padding-bottom: var(--gap-md);
    }
  }


  &__top {
    display: grid;
    grid-template-columns: 1fr var(--icon-sm);
    align-items: center;
    gap: var(--gap-xs);
    cursor: pointer;
    list-style: none;

    @include media-down(xl) {
      padding-top: var(--gap-sm);
    }

    @include media-up(xl) {
      padding-top: var(--gap-md);
    }

    &::-webkit-details-marker,
    &::marker {
      display: none;
    }
  }

  &__title {
    min-height: calc(var(--line-height-title) + var(--gap-2xs));
  }

  &__qty,
  &__val {
    display: inline-block;
    vertical-align: baseline;
    font-weight: var(--font-weight-semibold);
    line-height: var(--line-height-title);
    margin-left: 2px;
  }

  &__qty {
    font-size: var(--font-size-xs);
    min-width: var(--line-height-title);
    background-color: var(--clr-primary);
    color: var(--clr-white);
    border-radius: calc(var(--line-height-title) / 2);
    text-align: center;
  }

  &__val {
    color: var(--clr-primary);

    @include media-down(xl) {
      font-size: var(--font-size-xs);
    }

    @include media-up(xl) {
      font-size: var(--font-size-sm);
    }
  }

  &__marker {
    color: var(--clr-marker);
    transform: rotate(var(--rotate-marker));
  }
}
