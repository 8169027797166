.prod-articles {
  position: relative;

  @include media-down(md) {
    max-width: 420px;
    margin: 0 auto;
  }

  &__pagination {
    margin-top: var(--gap-sm);
    width: 100% !important;
  }
}

.prod-article {
  background-color: var(--clr-bg-light);
  border-radius: var(--radius-sm);
  position: relative;
  overflow: hidden;

  @include media-up(md) {
    display: flex;
  }

  &__cover {
    min-height: 124px;
    @include img-cover;

    @include media-up(md) {
      flex-basis: 280px;
      flex-shrink: 0;
    }
  }

  &__body {
    flex-grow: 1;
    padding: var(--gap-sm) var(--gap-md);
    display: flex;
    flex-direction: column;
    gap: var(--gap-xs);
    justify-content: space-between;
  }

  &__title {
    font-weight: var(--font-weight-semibold);
    line-height: 128%;
  }

  &__more {
    align-self: flex-end;

    a {
      &::before {
        position: absolute;
        content: '';
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }
    }
  }
}

.prod-articles-pagination {
  display: flex;
  justify-content: center;
  gap: var(--gap-sm);

  &.swiper-pagination-lock {
    display: none;
  }

  .swiper-pagination-bullet {
    flex-shrink: 0;
    width: var(--icon-2xs);
    height: var(--icon-2xs);
    background-color: var(--clr-primary);
    border-radius: 50%;
    cursor: pointer;

    &.swiper-pagination-bullet-active {
      background-color: var(--clr-black);
    }
  }
}

.prod-share {
  &__label {
    font-weight: var(--font-weight-semibold);
    margin-bottom: var(--gap-sm);
  }

  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    gap: var(--gap-xl);
  }
}
