.tbl-wrap {
  &--offset {
    --offset-gap-h: var(--gap-h, var(--gap-2xl));
    --offset-gap-v: var(--gap-v);
    margin-left: calc(var(--offset-gap-h) * -1);
    margin-right: calc(var(--offset-gap-h) * -1);
    margin-bottom: calc(var(--offset-gap-v) * -1);
  }
}

.tbl {
  width: 100%;
  border-spacing: 0;

  th,
  td {
    &:not(:first-child) {
      padding-left: var(--gap-xs);
    }

    &:not(:last-child) {
      padding-right: var(--gap-xs);
    }

    &:first-child {
      padding-left: var(--offset-gap-h, 0);
    }

    &:last-child {
      padding-right: var(--offset-gap-h, 0);
    }
  }

  th {
    font-weight: normal;
    text-align: left;
    padding-top: var(--gap-sm);
    padding-bottom: var(--gap-sm);
    border-bottom: 1px solid var(--clr-border-input);
  }

  td {
    padding-top: var(--gap-md);
    padding-bottom: var(--gap-md);
  }

  tr {
    &:not(:last-child) {
      td {
        @include media-up(md) {
          border-bottom: 1px solid var(--clr-border-input);
        }
      }
    }
  }

  &--border-bottom {
    @include media-up(md) {
      border-bottom: 1px solid var(--clr-border-input);
    }
  }
}