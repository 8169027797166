:root {
  // динамическое значение отступа от нижней границы экрана в зависимости от того, видно ли мобильное меню
  --bottom-offset: 64px;

  @include media-up(md) {
    --bottom-offset: 0px;
  }
}

.bottom-nav {
  @include media-down(md) {
    position: sticky;
    bottom: 0;
    padding: 0 var(--gap-xs);
    z-index: 11000;
    background-color: hsl(var(--clr-white-h) var(--clr-white-s) var(--clr-white-l) / 92%);
    backdrop-filter: saturate(180%) blur(20px);

    &::before {
      position: absolute;
      content: '';
      left: 0;
      right: 0;
      top: 0;
      height: 1px;
      background-color: var(--clr-border-input);
    }
  }

  @include media-up(md) {
    display: none;
  }

  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: space-between;
    position: relative;
  }

  &__item {
    flex: 1 1 0;
  }
}

.bottom-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--gap-2xs);
  color: var(--clr-gray);
  padding: var(--gap-sm) 0;
  width: 100%;

  &--has-new {
    --marker-bg-color: var(--clr-primary);
  }

  &.is-active {
    color: var(--clr-black);
  }

  &__icon {
    position: relative;

    &::before {
      position: absolute;
      content: '';
      width: var(--gap-xs);
      height: var(--gap-xs);
      border-radius: 50%;
      background-color: var(--marker-bg-color, transparent);
      top: calc(var(--gap-2xs) * -1);
      right: calc(var(--gap-2xs) * -1);
    }
  }

  &__label {
    font-weight: var(--font-weight-medium);
    font-size: var(--font-size-2xs);
    line-height: var(--font-size-xs);
  }
}
