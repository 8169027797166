picture {
  &.lazy {
    img {
      opacity: 0;
      transition: opacity var(--duration-sm);

      @at-root picture.img--loaded img {
        opacity: 1;
      }
    }

    &:not(.img--loaded) {
      position: relative;
      display: inline-block;
      vertical-align: middle;

      &:after {
        animation-duration: 1.6s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        animation-name: placeHolderShimmer;
        animation-timing-function: linear;
        background: var(--clr-bg-light);
        background: linear-gradient(
            to right,
            hsl(var(--clr-primary-h) var(--clr-primary-s) var(--clr-primary-l) / 3%) 8%,
            hsl(var(--clr-primary-h) var(--clr-primary-s) var(--clr-primary-l) / 25%) 38%,
            hsl(var(--clr-primary-h) var(--clr-primary-s) var(--clr-primary-l) / 3%) 54%
        );
        background-size: 1000px 640px;
        display: block;
        content: '';
        inset: 0;
        position: absolute;
      }
    }
  }
}

picture > img[loading],
:not(picture) > img[loading] {
  transition: opacity var(--duration-sm);
}

picture:not(.img--loaded) > img[loading],
:not(picture) > img:not(.img--loaded)[loading] {
  opacity: 0;
}

picture:not(.img--loaded):has(img[loading]) {
  display: inline-block;
  position: relative;
  vertical-align: middle;

  &.img--fill {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

picture:not(.img--loaded):has(img[loading="eager"]) {
  background: var(--bg-color, var(--clr-bg-light));
}

picture.img--loaded img[loading],
img.img--loaded[loading] {
  opacity: 1;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -500px 0
  }
  100% {
    background-position: 500px 0
  }
}
