.letters-nav {
  position: relative;

  &__wrap {
    overflow-x: auto;
    white-space: nowrap;
    scroll-behavior: auto;
    scrollbar-width: none;
    display: flex;

    @include media-down(lg) {
      --letter-size: var(--control-height-sm);
    }

    @include media-up(lg) {
      --letter-size: var(--control-height-md);
    }

    &::-webkit-scrollbar {
      display: none;
    }

    &::after {
      position: absolute;
      content: '';
      height: 100%;
      width: var(--gap-2xl);
      right: -2px;
      bottom: 0;
      z-index: 2;
      pointer-events: none;
      background: linear-gradient(to left, hsl(var(--clr-white-h) var(--clr-white-s) var(--clr-white-l) / 1) 0%, hsla(var(--clr-white-h) var(--clr-white-s) var(--clr-white-l) / 0) 100%);
    }
  }

  &__list {
    position: relative;
    margin: 0 auto;
    padding: 0;
    list-style: none;
    flex-shrink: 0;
  }

  &__item {
    display: inline-block;

    &:last-child {
      margin-right: var(--gap-2xl);
    }
  }

  &__link {
    display: block;
    min-width: var(--letter-size);
    line-height: calc(var(--letter-size) - 2px);
    text-transform: uppercase;
    text-align: center;
    color: var(--clr-black);
    border: 1px solid var(--clr-border-input-hover);
    border-radius: var(--radius-sm);
    padding: 0 var(--gap-xs);
    white-space: nowrap;
    transition: var(--duration-sm);

    &.is-active {
      border-color: var(--clr-black);
    }

    &.disabled {
      border-color: var(--clr-border-input);
      color: var(--clr-border-input-hover);
      pointer-events: none;
    }

    &:hover {
      background-color: var(--clr-primary);
      border-color: var(--clr-primary);
    }
  }
}

.abc-nav {
  &__letter {
    font-weight: var(--font-weight-semibold);
    font-size: clamp(var(--font-size-letter-min), 6vw, var(--font-size-letter-max));
    line-height: 1;
  }

  &__anchor {
    position: absolute;
    margin-top: calc((var(--control-height-md) + var(--gap-2xl) + var(--page-header-offset)) * -1);
  }

  &__main {
    margin-top: var(--gap-2xl);
  }

  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
    column-gap: var(--gap-3xl);

    @include media-up(sm) {
      column-count: 2;
    }

    @include media-up(md) {
      column-count: 3;
    }

    @include media-up(lg) {
      column-count: 4;
    }
  }

  &__item {
    break-inside: avoid;
    page-break-inside: avoid;
    column-fill: auto;
    margin-bottom: var(--gap-sm);
  }

  &__link {
    transition: var(--duration-sm);

    &:hover {
      color: var(--clr-primary);
    }
  }
}
