@mixin visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(100%);
}

@mixin img-center($object-fit: contain) {
  position: relative;
  overflow: hidden;

  img,
  video {
    position: absolute;
    max-width: 100%;
    max-height: 100%;
    object-fit: $object-fit;
    margin: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

@mixin img-cover {
  position: relative;
  overflow: hidden;

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

@mixin scrollbar-v {
  --scrollbar-color: #EBEDF3;
  --scrollbar-hover-color: var(--clr-border-input-hover);
  --scrollbar-size-base: var(--gap-xs);
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: var(--scrollbar-color) transparent;

  &::-webkit-scrollbar {
    width: var(--scrollbar-size-base);
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--scrollbar-color);
    border-radius: calc(var(--scrollbar-size-base) / 2);
    transition: .24s;

    &:hover {
      background-color: var(--scrollbar-hover-color);
    }
  }
}

//MEDIA
@mixin media-up($media) {
  @if $media == xs {
    @media (min-width: $screen-xs) {
      @content;
    }
  } @else if $media == sm {
    @media (min-width: $screen-sm) {
      @content;
    }
  } @else if $media == md {
    @media (min-width: $screen-md) {
      @content;
    }
  } @else if $media == lg {
    @media (min-width: $screen-lg) {
      @content;
    }
  } @else if $media == xl {
    @media (min-width: $screen-xl) {
      @content;
    }
  }
}

@mixin media-down($media) {
  @if $media == xs {
    @media (max-width: ($screen-xs - .02)) {
      @content;
    }
  } @else if $media == sm {
    @media (max-width: ($screen-sm - .02)) {
      @content;
    }
  } @else if $media == md {
    @media (max-width: ($screen-md - .02)) {
      @content;
    }
  } @else if $media == lg {
    @media (max-width: ($screen-lg - .02)) {
      @content;
    }
  } @else if $media == xl {
    @media (max-width: ($screen-xl - .02)) {
      @content;
    }
  }
}

@mixin media-only($media) {
  @if $media == xs {
    @media (min-width: $screen-xs) and (max-width: ($screen-sm - .02)) {
      @content;
    }
  } @else if $media == sm {
    @media (min-width: $screen-sm) and (max-width: ($screen-md - .02)) {
      @content;
    }
  } @else if $media == md {
    @media (min-width: $screen-md) and (max-width: ($screen-lg - .02)) {
      @content;
    }
  } @else if $media == lg {
    @media (min-width: $screen-lg) and (max-width: ($screen-xl - .02)) {
      @content;
    }
  }
}
