.pagination {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--gap-2xl);

  &__list {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    gap: var(--gap-2xs);
  }

  &__item {
    @include media-down(xs) {
      &--collapsible {
        display: none;
      }
    }

    @include media-up(xs) {
      &--optional {
        display: none;
      }
    }
  }

  &__link,
  &__label {
    display: block;
    padding: var(--gap-sm) var(--gap-2xs);
    min-width: var(--control-height-md);
    text-align: center;
    line-height: var(--font-size-base);
    font-size: var(--font-size-sm);
    font-weight: var(--font-weight-medium);
  }

  &__link {
    border-radius: var(--radius-sm);
    transition: var(--duration-sm);

    &:hover {
      color: var(--clr-primary);
    }

    &.is-active {
      background-color: var(--clr-bg-light2);
      pointer-events: none;
      font-weight: var(--font-weight-semibold);
    }
  }
}
