.way-item {
  display: grid;
  grid-template-columns: var(--icon-2xl) 1fr;
  align-items: center;
  gap: var(--gap-md);

  &__visual {
    &--bg {
      background-color: var(--clr-bg-light);
      border-radius: var(--radius-sm);
    }

    svg {
      max-width: 100%;
      height: auto;
    }
  }

  &__name {
    font-weight: var(--font-weight-semibold);
  }
}

.address-teaser {
  --vertical-gap: clamp(var(--gap-sm), 2.8vw, var(--gap-lg));
  --horizontal-gap: clamp(var(--gap-md), 3.2vw, var(--gap-xl));
  display: flex;
  align-items: center;
  gap: var(--gap-xs);
  width: 100%;
  text-align: left;
  padding: var(--vertical-gap) var(--horizontal-gap);
  border: 1px solid var(--clr-border-input);
  border-radius: var(--radius-sm);
  transition: background-color var(--duration-sm);

  &:hover {
    --opacity-ctrl: 1;
    background-color: var(--clr-bg-light);
  }

  &__value {
    font-weight: var(--font-weight-semibold);
    flex-grow: 1;
  }

  &__ctrl {
    flex-shrink: 0;
  }

  &__icon {
    color: var(--clr-border-input-hover);
    opacity: var(--opacity-ctrl, 0);
    transition: opacity var(--duration-sm);
  }
}

.order-box {
  --gap-v: clamp(var(--gap-sm), 2.8vw, var(--gap-lg));
  --gap-h: clamp(var(--gap-md), 3.2vw, var(--gap-2xl));
  border: 1px solid var(--clr-border-input);
  border-radius: var(--radius-sm);
  overflow: hidden;

  &__top,
  &__main,
  &__bottom {
    padding: var(--gap-v) var(--gap-h);
  }

  &__top {
    background-color: var(--clr-bg-light);

    strong {
      font-weight: var(--font-weight-semibold);
    }
  }

  &__info {
    display: flex;
    gap: var(--gap-md);
  }

  &__data {
    flex-grow: 1;
  }

  &__stamp {
    flex-shrink: 0;
  }

  &__main {
    display: flex;
    flex-direction: column;
    gap: var(--gap-lg);
  }

  &__bottom {
    border-top: 1px solid var(--clr-border-input);
  }

  &__block-title {
    font-weight: var(--font-weight-medium);
    margin-bottom: var(--gap-2xs);
  }
}
