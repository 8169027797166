.review-wrap {
  display: grid;
  gap: clamp(var(--gap-md), 2.4vw, var(--gap-2xl));

  @include media-up(md) {
    grid-template-columns: 280px 1fr;
  }
}

.review-item {
  font-size: var(--font-size-sm);

  &__header {
    margin-bottom: var(--gap-md);

    &--main {
      display: flex;
      flex-direction: column;
    }

    &--answer {
      display: flex;
      align-items: center;
      gap: var(--gap-xs);
      margin-left: calc(calc(var(--logo-glyph-size-sm) + var(--gap-xs)) * -1);
    }
  }

  &__name {
    font-weight: bold;
  }

  &__datetime {
    display: block;
    font-size: var(--font-size-xs);
    color: var(--clr-gray);
    margin-bottom: var(--gap-2xs);
    order: -1;
  }

  &__output {
    margin-top: var(--gap-xs);
    display: flex;
    align-items: center;
    gap: var(--gap-lg);
  }

  &__answer {
    margin-top: var(--gap-2xl);

    @include media-down(sm) {
      margin-left: calc(var(--logo-glyph-size-sm) + var(--gap-xs));
    }

    @include media-up(sm) {
      margin-left: calc(var(--gap-3xl) * 2);
    }
  }

  &__footer {
    margin-top: var(--gap-sm);
    display: flex;
    flex-direction: column;
    gap: var(--gap-sm);
  }

  &__photos {
    display: flex;
    flex-wrap: wrap;
    gap: var(--gap-sm);

    &-item {
      flex-basis: var(--photo-md);
      aspect-ratio: 1/1;
      border: 2px solid var(--clr-bg-light);
      border-radius: var(--radius-xs);
      transition: var(--duration-sm);

      &:hover {
        border-color: var(--clr-border-light);
      }
    }
  }

  &__photo {
    display: block;
    width: 100%;
    height: 100%;
    position: relative;

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      margin: auto;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      object-fit: contain;
    }
  }

  &__voting {
    display: flex;
    align-items: center;
    gap: var(--gap-xs);
  }
}

.star-rating {
  position: relative;
  display: block;
  height: var(--icon-xs);
  width: calc(calc(var(--icon-xs) * 5) + calc(var(--gap-2xs) * 5));

  &::before,
  &::after {
    position: absolute;
    content: "";
    background-image: url("../img/icon-star-primary.svg");
    background-repeat: repeat-x;
    background-position: 0;
    background-size: calc(var(--icon-xs) + var(--gap-2xs)) var(--icon-xs);
    bottom: 0;
    left: 0;
    top: 0;
  }

  &::before {
    width: 100%;
    opacity: 0.2;
    filter: grayscale(100%);
  }

  &::after {
    width: calc(var(--rating, 0) * 20%);
  }
}
