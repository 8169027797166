.discount-teas {
  --grid-column-body: 1/2;
  --grid-row-body: 1/2;
  --grid-column-visual: 2/3;
  --grid-row-visual: 1/2;
  --bg-color: var(--clr-primary);
  --align-items: center;
  align-items: var(--align-items);
  display: grid;
  grid-template-columns: 1fr auto;
  border-radius: var(--radius-sm);
  overflow: hidden;
  background-color: var(--bg-color);
  min-height: 120px;

  &--0 {
    --bg-color: #FFDBB0;
  }

  &--3,
  &--4,
  &--21 {
    --bg-color: #FFD178;
  }

  &--5,
  &--22 {
    --bg-color: #FFBE8E;
  }

  &--6,
  &--7,
  &--23 {
    --bg-color: #FFA781;
  }

  &--10,
  &--25 {
    --bg-color: #FF978F;
  }

  &--15,
  &--30 {
    --bg-color: #FF8190;
  }

  &--one-time,
  &--sale {
    --grid-column-body: 1/2;
    --grid-row-body: 1/2;
    --grid-column-visual: 1/2;
    --grid-row-visual: 1/2;
    --align-items: stretch;
    grid-template-columns: 100%;
  }

  &__body {
    position: relative;
    grid-column: var(--grid-column-body);
    grid-row: var(--grid-row-body);
    z-index: 2;
    padding: var(--gap-xs) var(--gap-xs) var(--gap-xs) var(--gap-lg);
    color: var(--clr-white);
  }

  &__value {
    font-weight: 800;
    font-size: 2.5rem;
  }

  &__label {
    margin-top: var(--gap-2xs);
    font-weight: 600;
    font-size: var(--font-size-sm);
  }

  &__visual {
    grid-column: var(--grid-column-visual);
    grid-row: var(--grid-row-visual);
  }

  &__bg {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
      margin: auto;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }
}

.discount-box {
  position: relative;
  display: grid;

  &__visual,
  &__main {
    grid-column: 1/2;
    grid-row: 1/2;
  }

  &__visual {
    @include media-down(lg) {
      margin-left: calc(var(--gap-lg) * -1);
      margin-right: calc(var(--gap-lg) * -1);
    }

    @include media-up(lg) {
      img {
        border-radius: var(--radius-md);
      }
    }
  }

  &__main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__info {
    background-color: hsl(var(--clr-white-h) var(--clr-white-s) var(--clr-white-l) / .5);
    padding: clamp(var(--gap-xl), 6vw, var(--gap-2xl)) clamp(var(--gap-xl), 6vw, var(--gap-6xl));
    font-weight: var(--font-weight-semibold);
    font-size: clamp(var(--font-size-base), 2.8vw, var(--font-size-2xl));
    box-shadow: 0 16px 32px hsl(12deg 100% 50% / .2);
    border-radius: var(--radius-md);
    backdrop-filter: blur(var(--gap-xl));
    text-align: center;
    max-width: 680px;

    @include media-down(sm) {
      margin-top: var(--gap-2xl);
    }
  }
}
