.blank {
  display: flex;
  flex-direction: var(--direction);
  gap: var(--gap-lg);
  align-items: center;
  padding: var(--gap-2xl);
  transition: var(--duration-sm);

  &--offset-top {
    margin-top: calc(var(--gap-2xl) * -1);
  }

  &--column {
    --direction: column;
  }

  &--md {
    --cover-size: 120px;
    --font-size: var(--font-size-lg);
  }

  &__cover {
    width: var(--cover-size);
    height: var(--cover-size);
    color: var(--clr-border-light);

    img {
      width: 100%;
      height: auto;
    }
  }

  &__text {
    font-size: var(--font-size);
    font-weight: 300;
    line-height: 160%;
    color: var(--clr-gray);
    text-align: center;
  }
}
