.info-box {
  position: fixed;
  z-index: 10175;
  right: 0;
  left: 0;
  background: linear-gradient(
      135deg,
      var(--clr-orange) 0%,
      var(--clr-primary) 25%,
      var(--clr-primary) 75%,
      var(--clr-orange) 100%
  );

  @include media-down(md) {
    padding-inline: var(--gap-2xs);
    padding-block: var(--gap-sm);
    bottom: var(--gap-6xl);
    font-size: var(--font-size-sm);
  }

  @include media-up(md) {
    padding-inline: var(--gap-lg);
    padding-block: var(--gap-md);
    bottom: 0;
  }

  &__wrap {
    position: relative;
  }

  &__main {
    margin: 0 var(--gap-2xl);
  }

  &__text {
    color: var(--clr-white);
    text-align: center;
  }

  &__extra {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}
