.stars {
  --gap: var(--gap-sm);
  display: flex;
  margin-left: var(--gap);
  gap: var(--gap);

  svg {
    &:first-child {
      color: var(--clr-gray);
    }
  }
}
