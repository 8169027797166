.rich-item {
  display: flex;
  align-items: center;
  gap: var(--gap);
  font-size: var(--font-size);
  font-weight: var(--font-weight, normal);
  color: var(--color);

  + .rich-item {
    margin-top: var(--gap-xs);
  }

  &--xs {
    --gap: var(--gap-2xs);
    --font-size: var(--font-size-xs);
  }

  &--sm {
    --gap: var(--gap-xs);
    --font-size: var(--font-size-sm);
  }

  &--xl {
    --gap: var(--gap-md);
    --font-size: clamp(var(--font-size-lg), 3.2vw, var(--font-size-2xl));
    --font-weight: var(--font-weight-semibold);
  }

  &--black {
    --color: var(--clr-black);
  }

  &--primary {
    --color: var(--clr-primary);
  }

  &--success {
    --color: var(--clr-success);
  }

  &__icon {
    flex-shrink: 0;
  }

  &__label {
    line-height: 1;
  }
}
