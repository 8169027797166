@import "main";
@import "fixes/bitrix-widget";
@import "components/location";
//FIXME @import "parts/lazy";
//FIXME @import "parts/readmore";
//@import "parts/cart-fixes";
//@import "parts/dadata-suggestions";
//FIXME @import "parts/focus";
//@import "parts/gift-cards";
//FIXME @import "parts/swiper";
//FIXME @import "parts/cdek";
//@import "parts/order";
@import "blocks/sbp-widget";

.hidden {
  display: none !important;
}

.icon {
  position: relative;

  &:after { // FIXME this - it adds invisible loader to all icons
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    content: ' ';
    background-color: white;
    background-image: url('../img/loader.svg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100%;
    opacity: 0;
    transition: opacity 500ms;
  }

  &--loading:after {
    opacity: 0.9;
  }
}

.fourth-slider {
  min-height: 23.25rem;
}

.grecaptcha-badge {
  display: none !important;
}

fieldset[disabled]:not(.always-visible) {
  display: none;
}

.form-group--required .form-label:after {
  content: ' *';
  color: #FF6B90;
  display: inline;
}

.prod-offers {
  &.preloader--active {
    min-height: 441px;
  }

  &--legacy.preloader--active {
    min-height: 321px;
  }
}

.pswp {
  &__bg {
    --pswp-bg: white;
  }

  &__scroll-wrap {
    --pswp-icon-color: var(--clr-primary);
    --pswp-icon-stroke-color: white;
    --pswp-icon-color-secondary: white;

    .pswp__counter {
      font-weight: bold;
    }
  }
}

//@include media-down(md) {
//    .hidden-md-down {
//        display: none;
//    }
//}
//
//.h-main__logo-badge {
//    //FIXME background-color: $clr-co;
//    color: white;
//    width: 15.5em;
//    padding: 4px;
//    border-radius: 6px;
//    margin: 5px auto 0 auto;
//    text-align: center;
//    line-height: 1;
//    font-weight: bold;
//    font-size: 80%;
//    @include media-up(lg) {
//        margin-right: 7px;
//        margin-top: 3px;
//    }
//}
//
//.modal .suggestions-suggestions {
//    max-height: 160px;
//    overflow: auto;
//}
//
//[data-selected] {
//    //FIXME background-color: $bg-light;
//}
