.side-nav {
  @include media-up(lg) {
    position: sticky;
    top: var(--gap-xs)
  }

  &__list {
    list-style: none;
    margin: 0;
    padding: 0;

    @include media-down(xs) {
      margin-left: calc(var(--gap-xs) * -1);
      margin-right: calc(var(--gap-xs) * -1);
    }
  }
}

.side-link {
  display: grid;
  grid-template-columns: var(--icon-sm) 1fr;
  align-items: center;
  border-radius: var(--radius-sm);

  @include media-down(xs) {
    gap: var(--gap-sm);
    padding: var(--gap-sm) var(--gap-sm);
  }

  @include media-up(xs) {
    gap: var(--gap-xl);
    padding: var(--gap-md) var(--gap-xl);
  }

  &:hover {
    --label-color: var(--clr-primary);
  }

  &.is-active {
    background-color: var(--clr-bg-light);
    --label-font-weight: var(--font-weight-semibold);
    --label-color: var(--clr-black);
  }

  &__label {
    transition: var(--duration-sm);
    color: var(--label-color, var(--clr-black));
    font-weight: var(--label-font-weight, normal);

    @include media-down(xs) {
      font-size: var(--font-size-sm);
    }
  }
}
