.home-sale {
  display: grid;

  @include media-up(lg) {
    gap: var(--gap-3xl);
  }

  @include media-up(md) {
    grid-template-columns: repeat(4, 1fr);
  }

  @include media-up(xs) {
    grid-template-columns: repeat(2, 1fr);
    gap: var(--gap-xl);
  }

  @include media-down(xs) {
    gap: var(--gap-lg);
  }

  &__item {

    @include media-up(lg) {
      height: 320px;
    }

    @include media-up(md) {
      &:first-child {
        grid-column: 1/3;
      }
      &:last-child {
        grid-column: -1/-3;
      }
    }

    @include media-up(xs) {
      height: 280px;
    }

    @include media-only(xs) {
      &:first-child {
        grid-column: 1/3;
      }
      &:last-child {
        grid-column: -1/-3;
      }
    }

    @include media-down(xs) {
      height: 240px;
    }
  }
}

.h-sale-link {
  display: block;
  height: 100%;
  position: relative;
  overflow: hidden;
  border-radius: var(--radius-xs);

  &::after {
    position: absolute;
    content: '';
    width: 50%;
    height: 200%;
    background: linear-gradient(to left, transparent 0%, var(--clr-white) 45%, var(--clr-white) 55%, transparent 100%);
    opacity: .5;
    filter: blur(var(--gap-md));
    top: 0;
    left: 0;
    transform: translate(-100%, -50%) rotate(45deg);
    transition: var(--duration-lg);
  }

  &:hover {
    &::after {
      transform: translate(200%, 0%) rotate(45deg);
    }
  }

  &--light {
    color: var(--clr-white);
  }

  img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__main {
    position: relative;

    @include media-down(sm) {
      padding: var(--gap-lg);
    }

    @include media-up(sm) {
      padding: var(--gap-xl);
    }

    @include media-up(lg) {
      padding: var(--gap-2xl);
    }
  }

  &__title {
    margin: 0 0 var(--gap-sm);

    @include media-up(md) {
      font-size: 1.25rem;
    }

    @include media-down(md) {
      font-size: 1rem;
    }
  }

  &__value {
    font-weight: bold;
    display: inline-block;
    background-color: var(--clr-primary);
    color: var(--clr-white);
    padding: var(--gap-2xs) var(--gap-xs);
    border-radius: var(--radius-xs);

    @include media-up(md) {
      font-size: 1.25rem;
    }

    @include media-down(md) {
      font-size: 1rem;
    }
  }
}
