.error-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: var(--clr-gray);

  &__code,
  &__title {
    font-weight: var(--font-weight-medium);
  }

  &__code {
    font-size: 6em;
  }

  &__title {
    font-size: clamp(var(--font-size-block-title-min), 4vw, var(--font-size-block-title-max));
    margin-bottom: 0.64em;
  }

  &__text {
    max-width: 50em;
  }
}
