.toasts-list {
  position: fixed;
  top: var(--gap-lg);
  right: var(--gap-lg);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: var(--gap-sm);
  width: calc(100% - calc(var(--gap-lg) * 2));
  max-width: 400px;
  z-index: 10400;
  pointer-events: none;
}

.toast {
  background-color: var(--clr-white);
  color: var(--toast-color, var(--clr-primary));
  border: 1px solid var(--toast-color, var(--clr-primary));
  border-radius: var(--radius-md);
  padding: var(--gap-sm);
  transform: translateX(calc(100% + var(--gap-lg)));
  transition: all var(--duration-md) cubic-bezier(0.68, -0.55, 0.25, 1.35);
  pointer-events: initial;

  &--bottom {
    top: unset;
    right: unset;
    left: var(--gap-lg);
    bottom: calc(var(--gap-lg) + var(--bottom-offset, 0px));
    transform: translateX(calc(-1 * (100% + var(--gap-lg))));
  }

  &.is-active {
    --animation-name: toast-progress;
    transform: translateX(0);
  }

  &__wrap {
    display: grid;
    grid-template-columns: 1fr max-content;
    gap: var(--gap-sm);
  }

  &__info {
    display: flex;
    gap: var(--gap-sm);
  }

  &__text {
    font-size: var(--font-size-sm);
    flex-grow: 1;
    min-height: var(--icon-xl);
    display: flex;
    align-items: center;
  }

  &__close {
    margin-right: calc(var(--gap-xs) * -1);
    margin-top: calc(var(--gap-xs) * -1);
  }

  &__progress {
    --animation-state: paused;
    --animation-duration: 10s;
    position: relative;
    grid-column: 1 / -1;
    height: 1px;
    background-color: var(--clr-border-input);
    transition: var(--duration-sm);

    &--running {
      --animation-state: forwards;
    }

    &--quick {
      --animation-duration: calc(2s - var(--duration-sm));
    }

    &--invisible {
      opacity: 0;
      position: absolute;
    }

    &::before {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      height: 100%;
      background-color: var(--toast-color, var(--clr-primary));
      width: 0;
      transition: var(--duration-sm);
      animation-delay: var(--duration-sm);
      animation-name: var(--animation-name);
      animation-fill-mode: forwards;
      animation-play-state: var(--animation-state);
      animation-duration: var(--animation-duration);
      animation-timing-function: linear;
    }

    &--reset {
      --animation-name: reset;
    }
  }

  &:hover &__progress {
    --animation-state: paused;
  }

  &__icon .text-preloader {
    top: 0;
  }
}

@keyframes toast-progress {
  to {
    width: 100%;
  }
}
