.breadcrumbs {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  gap: var(--gap-xs);
  flex-wrap: wrap;
  font-size: var(--font-size-sm);

  &__item {

    &:not(:last-child) {
      &::after {
        content: '';
        width: var(--icon-2xs);
        height: var(--icon-2xs);
        display: inline-block;
        vertical-align: middle;
        background-image: url('../img/sprite.svg#arrow-right-8');
        background-repeat: no-repeat;
        opacity: .4;
        margin-left: var(--gap-2xs);
      }
    }
  }

  &__link {
    transition: var(--duration-sm);

    &:hover {
      color: var(--clr-primary);
    }
  }

  &__current {
    color: var(--clr-gray);
  }
}
