.preloader {
  &:not(&--inline) {
    display: none;

    &.preloader--enabled {
      display: block;
    }
  }

  &--enabled {
    pointer-events: auto;

    &:after {
      position: fixed;
      z-index: 20000;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      opacity: 0;
      background-color: rgba(#fff, .75);
      transition: var(--duration-md) opacity;
      background-image: url('../../img/loader.svg');
      background-repeat: no-repeat;
      background-position: var(--preloader-position, center center);
      background-attachment: var(--preloader-attachment, scroll);
      background-size: 64px;
      content: ' ';
      display: block;
    }
  }

  &--active {
    &:after {
      opacity: 1;
      cursor: wait;
    }

    & > * {
      pointer-events: none;
    }
  }

  &--inline {
    position: relative;

    &.preloader--enabled {
      z-index: 2000;
    }

    &:after {
      position: absolute !important;
    }
  }

  &--transparent {
    &.preloader--enabled > * {
      transition: var(--duration-md) opacity;
    }

    &.preloader--active > * {
      opacity: .20;
    }

    &:after {
      background-color: transparent;
    }
  }
}

.text-preloader {
  display: inline-block;
  width: 18px;
  height: 18px;
  background: url("../../img/loader.svg");
  background-size: 100%;
  vertical-align: middle;
  top: -3px;
  position: relative;
  margin-left: 9px;
  opacity: 0;
  transition: var(--duration-md) opacity;

  &.is-active {
    opacity: 1;
  }
}
