.lbl {
  display: inline-flex;
  vertical-align: middle;
  font-size: var(--font-size);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height);
  color: var(--color);
  padding: var(--v-gap, 0) var(--h-gap, 0);
  background-color: var(--bg-color, transparent);
  border-radius: var(--radius, 0);

  &--sm {
    --font-size: var(--font-size-xs);
    --line-height: var(--icon-sm);
    --v-gap: var(--gap-2xs);
    --h-gap: var(--gap-xs);
    --radius: var(--radius-sm);
  }

  &--gray {
    --color: var(--clr-gray);
    --bg-color: var(--clr-bg-light);
  }
}
