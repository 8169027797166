.modal {
  --modal-transition: var(--duration-md);
  --modal-transform: translateY(5rem);
  position: fixed;
  border: 0;
  margin: 0;
  padding: var(--gap-sm);
  width: 100vw;
  height: 100vh;
  max-width: 100vw;
  max-height: 100vh;
  display: flex;
  align-items: var(--align-items, center);
  justify-content: var(--justify-content, center);
  background-color: transparent;
  pointer-events: none;
  opacity: 0;
  transition: opacity var(--modal-transition);
  overflow: hidden;

  @media (hover: none) {
    --align-items: flex-start;
  }

  &.dialog-polyfill-as-modal {
    &.is-open {
      z-index: 20010 !important;

      + .backdrop {
        z-index: 20020 !important;
      }
    }
  }

  &::backdrop,
  + .backdrop {
    background-color: transparent;
    backdrop-filter: blur(0px);
    transition: background-color var(--duration-md), backdrop-filter var(--duration-md);
  }

  &.is-open {
    --modal-transform: translateY(0);
    opacity: 1;
    pointer-events: auto;

    &::backdrop,
    + .backdrop {
      background-color: hsl(var(--clr-primary-hsl) / .16);
      backdrop-filter: blur(var(--gap-2xs));
    }
  }

  &.is-closing {
    --modal-transform: translateY(-5rem);
  }

  &--sm {
    --width: 380px;
  }

  &--md {
    --width: 448px;
  }

  &--lg {
    --width: 640px;
  }

  &--xl {
    --width: 1044px;
  }

  &--2xl {
    --width: var(--container-lg);
  }

  &__wrapper {
    position: relative;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    width: 100%;
    max-height: 100%;
    max-height: min(100%, 100svh);
    gap: var(--gap-md);
    max-width: var(--width);
    background-color: var(--clr-white);
    transform: var(--modal-transform);
    transition: transform var(--modal-transition), opacity var(--modal-transition);

    @include media-down(sm) {
      padding: var(--gap-lg);
    }

    @include media-up(sm) {
      padding: var(--gap-3xl);
      border-radius: var(--border-radius, var(--radius-md));
    }
  }

  &__top {
    display: flex;
    gap: var(--gap-md);
    justify-content: var(--top-justify-content, flex-start);
  }

  &__title {
    flex-grow: 1;
    font-size: clamp(var(--font-size-modal-title-min), 4vw, var(--font-size-modal-title-max));
    line-height: var(--gap-2xl);
  }

  &__close {
    margin-left: auto;

    .btn {
      &:focus-visible {
        outline: none;
      }
    }
  }

  &__main {
    position: relative;
    flex-grow: 1;
    overflow-x: hidden;
    padding-left: var(--gap-xs);
    padding-right: var(--gap-xs);
    margin-left: calc(var(--gap-xs) * -1);
    margin-right: calc(var(--gap-xs) * -1);
    @include scrollbar-v;

    &::before,
    &::after {
      --scroll-buffer: var(--gap-2xl);
      content: '';
      display: block;
      position: sticky;
      height: 1px;
      background-color: var(--clr-border-input);
      opacity: 0;
      animation: reveal both linear;
      animation-timeline: scroll(nearest);
      z-index: 2;
      box-shadow: 0 0 var(--gap-md);
    }

    &::before {
      top: 0;
      animation-range: 0 var(--scroll-buffer);
    }

    &::after {
      bottom: 0;
      animation-direction: reverse;
      animation-range: calc(100% - var(--scroll-buffer)) 100%;
    }


    &--sm {
      min-height: 240px;
    }
  }

  &__body {
    margin-top: var(--gap-md);
    margin-bottom: var(--gap-md);
  }

  &__block {
    &:not(:last-child) {
      margin-bottom: var(--gap-2xl);
    }
  }

  &__extra {
    &--mh {
      max-height: calc(var(--photo-sm) * 5.25);
      @include scrollbar-v;
    }
  }
}
