:root {
  --font-family-base: Montserrat, sans-serif;
  --font-family-extra: Comfortaa, sans-serif;

  --font-size-base: 1rem; //16px
  --font-size-2xs: 0.625rem; //10px
  --font-size-xs: 0.75rem; //12px
  --font-size-sm: 0.875rem; //14px
  --font-size-lg: 1.125rem; //18px
  --font-size-xl: 1.25rem; //20px
  --font-size-2xl: 1.5rem; //24px
  --font-size-3xl: 1.75rem; //28px
  --font-size-4xl: 2rem; //32px
  --font-size-5xl: 2.25rem; //36px
  --font-size-6xl: 2.5rem; //40px

  --font-size-box-title-min: var(--font-size-lg); //18px
  --font-size-box-title-max: var(--font-size-xl); //20px

  --font-size-block-title-min: 1.5rem; //24px
  --font-size-block-title-max: 2.25rem; //36px

  --font-size-letter-min: 3rem; //48px
  --font-size-letter-max: 4rem; //64px

  --font-size-modal-title-min: 1.25rem; //20px
  --font-size-modal-title-max: 1.5rem; //24px

  --font-weight-medium: 500;
  --font-weight-semibold: 600;
  --font-weight-bold: 700;
  --font-weight-extrabold: 800;
}
