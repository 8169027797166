.prod-line {
  --cover-size: var(--photo-md);
  --gap: var(--gap-2xl);
  display: grid;
  grid-template-columns: var(--cover-size) 1fr;
  gap: var(--gap);
  align-items: center;
  transition: color var(--duration-sm);

  @at-root a {
    &.prod-line {
      &:hover {
        color: var(--clr-primary);
      }
    }
  }

  &--sm {
    --cover-size: var(--photo-xs);
    --gap: var(--gap-md);
    --font-size: var(--font-size-xs);
  }

  &--lg {
    --font-size: var(--font-size-xl);
  }

  &--extra {
    --info-display: inline;

    @include media-down(md) {
      --gap: var(--gap-lg);
    }
  }

  &--inactive {
    opacity: var(--opacity-inactive);
  }

  &__cover {
    position: relative;
    width: var(--cover-size);
    height: var(--cover-size);
    background-color: var(--clr-white);
    @include img-center;
  }

  &__main {
    font-size: var(--font-size);
    display: flex;
    gap: var(--gap);
  }

  &__info {
    flex-grow: 1;
  }

  &__brand,
  &__name,
  &__offer {
    display: var(--info-display, block);
  }

  &__brand,
  &__price {
    font-weight: var(--font-weight-bold);
  }

  &__price {
    white-space: nowrap;
  }
}
