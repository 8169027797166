.activity-coupon {
  &--100 {
    --bg-color: #ffa781;
  }

  &--200 {
    --bg-color: #ff978f;
  }

  &--250 {
    --bg-color: #ff8190;
  }

  &--400 {
    --bg-color: var(--clr-primary);
  }


  &__icon {
    max-width: max-content;
    margin: 0 auto var(--gap-xs);
  }

  &__title {
    text-align: center;
    font-size: var(--font-size-lg);
    font-weight: var(--font-weight-semibold);
  }

  &__main {
    display: grid;
    grid-template-columns: repeat(2, auto);
    grid-template-rows: repeat(3, auto);
    gap: var(--gap-2xs);
    margin: var(--gap-md) 0 var(--gap-xl);
    color: var(--clr-white);
    background-color: var(--bg-color);
    border-radius: var(--radius-sm);
    padding: var(--gap-sm) var(--gap-md);
    font-weight: var(--font-weight-semibold);
  }

  &__extra {
    font-size: var(--font-size-sm);
    text-align: right;
  }

  &__value {
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-6xl);
    grid-column: 1/3;
  }

  &__label {
    grid-column: 1/2;
    font-size: var(--font-size-sm);
  }

  &__limit {
    text-align: right;
    grid-column: 2/3;
    grid-row: 3/4;
  }

  &__bottom {
    min-height: var(--control-height-md);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}