.my-promo {
  position: relative;
  display: flex;
  align-items: flex-start;
  gap: var(--gap-xs);

  &--invalid {
    --status-color: var(--clr-primary);
  }

  &__value {
    font-size: var(--font-size-sm);
    font-weight: var(--font-weight-medium);
    color: var(--status-color, var(--clr-black));
    margin-right: auto;
  }

  &__promo,
  &__extra {
    flex: 1 1 0;
  }

  &__extra {
    display: flex;
    align-items: center;
    gap: var(--gap-sm);
    height: var(--control-height-xs);

    .btn {
      &[data-tooltip] {
        &::after {
          text-align: right;
          max-width: 220px;
          right: 0;
        }
      }
    }
  }
}
