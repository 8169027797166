.page-block {
  --page-header-offset: 0px;
  padding: clamp(var(--gap-xl), 4vw, var(--gap-3xl)) 0;
  flex-grow: 1;

  &__wrap {
    display: grid;
    grid-template-columns: 100%;
    gap: clamp(var(--gap-lg), 4vw, var(--gap-3xl));
  }

  &__top {
    display: flex;
    gap: var(--gap-md);
    align-items: center;
  }

  &__head {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: clamp(var(--gap-lg), 4vw, var(--gap-2xl));
  }

  &__time {
    margin-top: calc(clamp(var(--gap-md), 3vw, var(--gap-xl)) * -1);
  }

  &__controls {
    display: flex;
    flex-wrap: wrap;
    gap: clamp(var(--gap-sm), 1.6vw, var(--gap-xl));
    align-items: center;
    justify-content: space-between;
  }

  &__filter {
    flex-grow: 1;
    max-width: 100%;
  }

  &__switch-view {
    .label {
      @include media-down(xs) {
        display: none;
      }
    }
  }

  &__over-main {
    &--sticky {
      background-color: var(--clr-white);
      z-index: 2;
      position: sticky;
      top: calc(var(--page-header-offset) + var(--gap-2xs));
      will-change: top;
      transition: top var(--duration-sm);
    }
  }

  &__main {
    &--side {
      display: grid;

      @include media-down(md) {
        gap: var(--gap-xl);
      }

      @include media-up(md) {
        --side-size: var(--side-size-lg);
        gap: var(--gap-3xl);
        grid-template-columns: var(--side-size) 1fr;
        padding-top: clamp(var(--gap-xl), 4vw, var(--gap-3xl));
        border-top: 1px solid var(--clr-border-input);
      }
    }

    &--md {
      max-width: 840px;
      margin: 0 auto;
    }
  }

  &__side,
  &__body {
    min-width: 0;
  }

  &__bottom {
    margin-top: clamp(var(--gap-lg), 3.2vw, var(--gap-3xl));
  }

  &__box {
    &:not(:last-child) {
      margin-bottom: clamp(var(--gap-2xl), 6vw, var(--gap-5xl));
    }
  }

  &__over-content {
    margin-bottom: var(--gap-4xl);
  }

  &__under-content,
  &__pagination {
    margin-top: var(--gap-4xl);
  }
}

.page-cover {
  @include media-down(md) {
    margin-inline: calc(var(--gap-lg) * -1);
  }

  @include media-up(md) {
    max-width: max-content;
    margin: 0 auto;
  }

  img {
    @include media-up(md) {
      border-radius: var(--radius-sm);
    }
  }
}

@include media-down(lg) {
  .header:not(.is-hide) + .page-block {
    --page-header-offset: var(--header-min-height);
  }
}

.page-cover {
  &--center {
    max-width: max-content;
    margin: 0 auto;
  }
}
