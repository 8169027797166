.block {
  &--extra {
    --gap: clamp(var(--gap-2xl), 4vw, var(--gap-3xl));
    --top-gap: clamp(var(--gap-2xl), 4vw, var(--gap-4xl));
    --bottom-gap: clamp(var(--gap-md), 4vw, var(--gap-2xl));
  }

  &--sm {
    --gap: clamp(var(--gap-sm), 3.2vw, var(--gap-lg));
  }

  &__wrap {
    display: grid;
    grid-template-columns: 100%;
    gap: var(--gap, clamp(var(--gap-xl), 4vw, var(--gap-2xl)));
    padding-top: var(--top-gap, 0);
    padding-bottom: var(--bottom-gap, 0);
  }

  &__top {
    display: flex;
    align-items: center;
    gap: var(--gap-md);
    flex-wrap: wrap;
  }

  &__head {
    flex-grow: 1;
    max-width: 100%;
  }

  &__top-extra {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: var(--gap-2xl);

    @include media-down(lg) {
      flex-grow: 1;
      flex-basis: 100%;
    }
  }

  &__arrows {
    display: flex;
    align-items: center;
    gap: var(--gap-sm);
    margin-left: auto;

    &--prod-similar {
      margin-top: calc(var(--gap-xs) * -1);
      margin-bottom: var(--gap-md);
      justify-content: flex-end;
    }
  }

  &__arrow {
    transition: var(--duration-sm);

    &.swiper-button-disabled {
      color: var(--clr-gray);
      pointer-events: none;
    }

    &.swiper-button-lock {
      display: none;
    }

    &:hover {
      color: var(--clr-primary);
    }
  }

  &__sub-block {
    &:not(:last-child) {
      margin-bottom: clamp(var(--gap-lg), 4vw, var(--gap-2xl));
    }
  }
}

.block-title {
  margin: 0;
  font-weight: var(--font-weight-semibold);
  font-size: var(--font-size, clamp(var(--font-size-block-title-min), 4vw, var(--font-size-block-title-max)));
  line-height: 1.2;

  &--sm {
    --font-size: clamp(var(--font-size-xl), 4vw, var(--font-size-2xl));
  }

  &--weight-normal {
    font-weight: normal;
  }

  span {
    font-weight: normal;
  }

  i {
    font-style: normal;
    font-size: 132%;
  }
}
