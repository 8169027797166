.message {
  display: flex;
  gap: var(--gap-size);

  &--sm {
    --cover-size: var(--icon-lg);
    --gap-size: var(--gap-sm);
  }

  &--md {
    --cover-size: var(--icon-xl);
    --gap-size: var(--gap-xs);
  }

  &--strong {
    font-weight: var(--font-weight-semibold);
  }

  &__text {
    flex-grow: 1;
    min-height: var(--cover-size);
    display: flex;
    align-items: center;
  }
}