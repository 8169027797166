.delivery {
  &__main {
    margin-top: var(--gap-lg);
    background-color: var(--clr-bg-light);
    padding: clamp(var(--gap-lg), 4vw, var(--gap-3xl));
    display: flex;
    flex-direction: column;
    gap: var(--gap-lg);

    @include media-down(sm) {
      margin-left: calc(var(--gap-lg) * -1);
      margin-right: calc(var(--gap-lg) * -1);
    }
  }

  &__tips {
    font-size: var(--font-size-sm);

    .link {
      font-weight: var(--font-weight-bold);
    }
  }

  &__result {
    margin-top: var(--gap-lg);
    min-height: 337px;

    &-title {
      text-align: center;
      font-size: var(--font-size-xl);
      font-weight: var(--font-weight-semibold);
      margin-bottom: 1.6em;
    }
  }

  &__methods {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: clamp(var(--gap-lg), 4vw, var(--gap-3xl));
  }
}

.delivery-teaser {
  &__top {
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
    gap: var(--gap-2xs);
    margin-bottom: var(--gap-2xs);
  }

  &__name {
    font-weight: var(--font-weight-bold);
  }
}

.delivery-item {
  display: flex;
  gap: var(--gap-md);
  position: relative;

  @media (hover: hover) {
    --opacity-buttons: 0;

    &:hover,
    &:has(:focus-visible) {
      --opacity-buttons: 1;
    }
  }

  &:has(:checked) {
    --item-border-color: var(--clr-primary);
  }

  &--bordered {
    border: 1px solid var(--item-border-color, var(--clr-border));
    border-radius: var(--radius-md);
    padding: var(--gap-md);
    transition: border-color var(--duration-sm);
  }

  &--na {
    --opacity: .4;
  }

  &__wrap {
    flex-grow: 1
  }

  &__top {
    display: flex;
    align-items: center;
    gap: var(--gap-sm);
    margin-bottom: var(--gap-2xs);
    opacity: var(--opacity);
  }

  &__main {
    opacity: var(--opacity);
    margin-left: calc(var(--icon-2xl) + var(--gap-sm));
  }

  &__name {
    font-weight: var(--font-weight-medium);
  }

  &__info,
  &__message {
    font-size: var(--font-size-sm);
  }

  &__message {
    color: var(--clr-error-dark);
  }

  &__bottom,
  &__extra {
    margin-top: var(--gap-sm);
  }

  &__controls {
    &--buttons {
      display: flex;
      gap: var(--gap-2xs);
      opacity: var(--opacity-buttons);
      transition: opacity var(--duration-sm);
    }
  }

  &__today {
    position: relative;
  }

  &__cost-info {
    margin-top: var(--gap-sm);
  }
}
