.noUi-connects {
  border-radius: 2px;
  overflow: hidden;
  z-index: 0;
}

.noUi-base,
.noUi-connects {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
}

.noUi-connect,
.noUi-origin {
  will-change: transform;
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  transform-origin: 0 0;
  transform-style: flat;
}

.noUi-connect {
  background-color: var(--clr-primary);
}

.noUi-horizontal {
  height: 2px;
  background-color: var(--clr-black);

  .noUi-origin {
    height: 0;
  }

  .noUi-handle {
    width: var(--icon-sm);
    height: var(--icon-sm);
    right: calc(var(--icon-sm) / -2);
    top: calc(calc(var(--icon-sm) / -2) + 1px);
  }
}

.noUi-handle {
  border-radius: 50%;
  background-color: var(--clr-white);
  box-shadow: inset 0 0 0 1px var(--clr-black);
  cursor: move;
  backface-visibility: hidden;
  position: absolute;
}

.noUi-touch-area {
  height: 100%;
  width: 100%;
}
