.header {
  @include media-down(lg) {
    --logo-width: 220px;
  }

  @include media-up(lg) {
    --logo-width: 280px;
  }

  @include media-down(md) {
    position: sticky;
    top: 0;
    right: 0;
    left: 0;
    z-index: 16;
    background-color: hsl(var(--clr-white-h) var(--clr-white-s) var(--clr-white-l) / 88%);
    backdrop-filter: saturate(180%) blur(20px);
    transition: var(--duration-sm);

    &.is-hide {
      top: calc(var(--header-min-height) * -1);
    }
  }

  &__wrap {
    @include media-down(md) {
      position: relative;
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: center;
      gap: var(--gap-lg);
    }
  }
}

.h-main {
  @include media-down(md) {
    padding: var(--gap-sm) 0;
  }

  @include media-up(md) {
    padding: var(--gap-md) 0;
  }

  &__wrap {
    display: flex;
    align-items: center;
    gap: clamp(var(--gap-2xl), 4vw, calc(var(--gap-3xl) * 2));
  }

  &__logo {
    @include media-up(md) {
      flex-basis: var(--logo-width);
    }
  }

  &__nav {
    flex-grow: 1;
  }
}

.h-nav {
  @include media-down(md) {
    display: none;
  }

  &__list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: var(--gap-2xl);
  }

  &__link {
    position: relative;
    display: block;
    font-size: var(--font-size-lg);
    font-weight: var(--font-weight-medium);
    line-height: var(--gap-lg);
    padding: var(--gap-sm) 0;
    transition: color var(--duration-sm);

    &:hover {
      color: var(--clr-primary);
    }

    &.is-active {
      &::before {
        position: absolute;
        content: '';
        bottom: 0;
        left: 0;
        right: 0;
        height: 2px;
        background-color: var(--clr-primary);
      }
    }
  }
}

.logo {
  display: grid;
  column-gap: var(--gap-sm);
  row-gap: var(--gap-2xs);
  align-items: center;
  font-family: var(--font-family-extra);
  text-transform: uppercase;

  @include media-down(lg) {
    grid-template-columns: var(--logo-glyph-size-md) 1fr;
  }

  @include media-up(lg) {
    grid-template-columns: var(--logo-glyph-size-lg) 1fr;
  }

  &:hover {
    .logo__glyph {
      transform: rotate(-8deg);
    }
  }

  &__glyph {
    transition: transform var(--duration-sm);
    grid-row: span 2;
  }

  &__title,
  &__label {
    font-weight: 700;
  }

  &__title {
    letter-spacing: .36em;
    align-self: end;

    @include media-down(lg) {
      font-size: 22px;
    }

    @include media-up(lg) {
      font-size: 28px;
    }
  }

  &__label {
    font-size: 10px;
    letter-spacing: .16em;
    align-self: start;

    @include media-down(lg) {
      font-size: 8px;
    }

    @include media-up(lg) {
      font-size: 10px;
    }
  }
}

.h-top {
  padding: var(--gap-sm) 0;

  @include media-down(md) {
    flex-grow: 1;
  }

  @include media-up(md) {
    border-bottom: 1px solid var(--clr-border-input);
  }

  &__wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: clamp(var(--gap-2xl), 4vw, calc(var(--gap-3xl) * 2));
  }

  &__main {
    flex-grow: 1;
    display: flex;
    align-items: center;
    gap: var(--gap-md);

    @include media-up(md) {
      position: relative;
    }
  }

  &__location,
  &__nav {
    @include media-down(md) {
      display: none;
    }
  }

  &__location {
    @include media-up(md) {
      flex-basis: var(--logo-width);
    }
  }

  &__search {
    flex-grow: 1;
  }
}

.top-nav {
  &__list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    gap: 2vw;
  }
}

.top-search {
  position: relative;
  display: flex;
  align-items: center;
  gap: var(--gap-xs);
  margin: 0;
  transition: var(--duration-sm);

  @include media-down(sm) {
    background-color: var(--form-bg-color, transparent);
    margin-left: var(--form-margin-left, 0);
    margin-right: calc(var(--gap-xs) * -1);
    border-radius: var(--radius-sm);
    z-index: 2;

    &:focus-within {
      --form-margin-left: -186px;
      --form-bg-color: var(--clr-white);
    }
  }

  @include media-down(md) {
    --placeholder-color: transparent;
    --input-text-color: transparent;
    --input-padding-right: calc(var(--icon-sm) + var(--gap-md));

    &:focus-within {
      --icon-left: var(--gap-2xs);
      --input-padding-left: calc(var(--icon-sm) + var(--gap-md));
      --input-padding-right: 0;
      --placeholder-color: var(--clr-gray);
      --input-text-color: var(--clr-black);
    }
  }

  &__icon {
    flex-shrink: 0;
    transition: var(--duration-sm);
    left: var(--icon-left, calc(100% - var(--icon-lg)));
    padding: var(--gap-2xs);

    @include media-down(md) {
      position: absolute;
      pointer-events: none;
    }
  }

  &__input {
    flex-grow: 1;
    height: var(--control-height-md);
    transition: var(--duration-sm);
    border-radius: var(--radius-sm);
    padding-left: var(--input-padding-left, 0);
    padding-right: var(--input-padding-right, 0);
    color: var(--input-text-color);

    &::placeholder {
      color: var(--placeholder-color, var(--clr-gray));
      transition: var(--duration-sm);
    }
  }

  &:focus-within &__input:not(:placeholder-shown) + .top-search__clear {
    opacity: 1;
  }

  &__clear {
    position: absolute;
    right: 0;
    opacity: 0;
    color: var(--clr-gray);
    transition: opacity 150ms;
  }

  &__button {
    @include visually-hidden;
  }
}
