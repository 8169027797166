.notice {
  --gap-v: var(--gap-xs);
  --gap-h: var(--gap-lg);
  padding: var(--gap-v) var(--gap-h);
  max-width: max-content;
  line-height: 125%;
  font-size: var(--font-size, var(--font-size-base));

  &--xs {
    --font-size: var(--font-size-xs);
    --gap-v: var(--gap-2xs);
    --gap-h: var(--gap-sm);
  }

  &--sm {
    --font-size: var(--font-size-sm);
  }

  &--gray {
    color: var(--clr-gray);
  }

  &--bg {
    background-color: var(--clr-bg-light2);
    border-radius: var(--gap-h);
  }

  strong {
    font-weight: var(--font-weight-semibold);
  }
}