.product {
  &--page {
    padding-bottom: calc(var(--gap-3xl) * 2);
  }

  &__grid {
    display: grid;
    row-gap: var(--gap-lg);
    max-width: 100%;

    @include media-down(md) {
      grid-template-columns: 100%;
    }

    @include media-up(md) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      grid-template-rows: auto 1fr;
    }

    @include media-down(lg) {
      column-gap: var(--gap-xl);
    }

    @include media-up(lg) {
      column-gap: var(--gap-3xl);
    }
  }

  &__top {
    display: flex;
    flex-wrap: wrap;
    gap: var(--gap-sm);

    @include media-up(md) {
      grid-column: 2/3;
    }
  }

  &__callback {
    margin-left: auto;
  }

  &__title,
  &__description {
    flex-grow: 1;
    flex-basis: 100%;
    width: 100%;
  }

  &__title {
    margin: 0;
    line-height: 1.2;

    @include media-down(sm) {
      font-size: var(--font-size-block-title-min);
    }

    @include media-up(sm) {
      font-size: var(--font-size-block-title-max);
    }

    span {
      font-weight: var(--font-weight-medium);
    }
  }

  &__description {
    @include media-up(sm) {
      font-size: var(--font-size-lg);
    }
  }

  &__visual {
    @include media-down(md) {
      width: 100%;
      max-width: 580px;
      margin-left: auto;
      margin-right: auto;
    }

    @include media-up(md) {
      grid-column: 1/2;
      grid-row: 1/3;
    }
  }

  &__main {
    display: flex;
    flex-direction: column;
    gap: var(--gap-2xl);
  }

  &__over-offer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: var(--gap-xl);
  }
}

.prod-cover {
  @include media-up(md) {
    position: sticky;
    top: 0;
  }

  &__main {
    position: relative;
  }

  &__photo {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    aspect-ratio: 1.1923/1;

    @include img-center;

    picture,
    img {
      width: 100%;
      height: 100%;
    }
  }

  &__info {
    position: absolute;
    top: var(--gap-xs);
    left: var(--gap-xs);
    display: flex;
    flex-direction: column;
    gap: var(--gap-md);
    z-index: 2;
  }

  &__badges,
  &__stats {
    display: flex;
    flex-direction: column;
    gap: var(--gap-2xs);
  }

  &__msg {
    position: absolute;
    bottom: 8%;
    z-index: 2;
    left: 0;
    right: 0;
  }

  &__bottom {
    margin-top: var(--gap-3xl);
  }
}

.prod-slider {

  &__item {
    position: relative;

    &--video {
      &::before {
        position: absolute;
        content: '';
        width: var(--photo-lg);
        aspect-ratio: 1/1;
        background-image: url('../img/sprite-clr.svg#youtube-80');
        background-repeat: no-repeat;
        background-size: contain;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: .8;
        z-index: 1;
        pointer-events: none;
      }
    }
  }

  &__link {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    @include img-center;

    picture, img, video {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    @include media-down(xs) {
      aspect-ratio: 1/1;
    }

    @include media-up(xs) {
      aspect-ratio: 1.1923/1;
    }
  }

  video {
    pointer-events: none;
  }
}

.prod-msg {
  position: relative;
  padding: var(--gap-lg) var(--gap-xl);
  border: 1px solid var(--clr-border-input);
  border-radius: var(--radius-sm);
  background-color: hsl(var(--clr-white-h) var(--clr-white-s) var(--clr-white-l) / .6);
  backdrop-filter: blur(var(--gap-xl));

  &__wrap {
    display: grid;
    grid-template-columns: var(--icon-xl) 1fr;
    gap: var(--gap-md);
    align-items: center;
    padding-right: var(--gap-sm);
  }

  &__text {
    font-size: var(--font-size-sm);
  }

  &__btn {
    position: absolute;
    top: var(--gap-2xs);
    right: var(--gap-2xs);
  }
}

.prod-cover-nav {
  &__wrap {
    display: flex;
  }

  &__item {
    position: relative;
    width: var(--photo-md);
    padding-bottom: var(--gap-sm);
    margin-right: var(--gap-3xl);
    cursor: pointer;

    &--video {
      &::before {
        position: absolute;
        content: '';
        width: var(--photo-xs);
        aspect-ratio: 1/1;
        background-image: url('../img/sprite-clr.svg#youtube-80');
        background-repeat: no-repeat;
        background-size: contain;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: .8;
        z-index: 1;
        pointer-events: none;
      }
    }

    picture {
      display: block;
      width: 100%;
      aspect-ratio: 1/1;
      @include img-center;

      img {
        height: 100%;
        width: 100%;
      }
    }

    &::after {
      position: absolute;
      content: '';
      bottom: 0;
      left: 0;
      right: 0;
      height: 2px;
      transition: var(--duration-sm);
    }

    &.swiper-slide-thumb-active {
      &::after {
        background-color: var(--clr-primary);
      }
    }
  }
}

.prod-offers {
  display: flex;
  flex-direction: column;
  gap: var(--gap-xl);
}

.prod-offer {
  &__wrap {
    display: flex;
    flex-direction: column;
    gap: var(--gap-xl);
  }

  &__label {
    font-weight: var(--font-weight-semibold);
  }

  &__list {
    list-style: none;
    margin: var(--gap-sm) 0 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    gap: var(--gap-xs);
  }

  &__help {
    margin-top: var(--gap-md);

    &-btn {
      color: var(--clr-gray);
      font-size: var(--font-size-sm);
      border-bottom: 1px dashed;
    }
  }
}

.prod-offer-old {
  &__top {
    position: relative;

    &::before,
    &::after {
      position: absolute;
      content: '';
      height: 1px;
      background-color: var(--clr-border-input);
      z-index: 4;

      @include media-down(md) {
        left: calc(var(--gap-lg) * -1);
        right: calc(var(--gap-lg) * -1);
      }

      @include media-up(md) {
        left: 0;
        right: 0;
      }
    }

    &::before {
      top: 0;
    }

    &::after {
      bottom: 1px;
    }
  }

  &__main {
    padding: var(--gap-sm) 0;
  }

  &__list {
    margin: 0;
    padding: 0;
    padding-right: var(--gap-lg);
    list-style: none;
    max-height: 488px;
    @include scrollbar-v;

    &::after {
      display: block;
      content: '';
      height: 1px;
      background-color: var(--clr-border-input);
      margin-right: calc(var(--gap-lg) * -1);
      position: sticky;
      bottom: 0;
      --scroll-buffer: var(--gap-xs);
      opacity: 0;
      animation: reveal both linear;
      animation-timeline: scroll(nearest);
      animation-range: calc(100% - var(--scroll-buffer)) 100%;
      animation-direction: reverse;
    }

    @include media-down(md) {
      padding-left: var(--gap-lg);
      margin-left: calc(var(--gap-lg) * -1);
      margin-right: calc(var(--gap-lg) * -1);
    }
  }

  &__item {
    &:not(:last-child) {
      margin-bottom: var(--gap-xs);
    }

    &:last-child {
      margin-bottom: var(--gap-sm);
    }
  }

  &__bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: var(--gap-xs);
    gap: var(--gap-md);
  }
}

.prod-option {
  display: block;
  color: var(--text-color, var(--clr-black));

  &:hover {
    --bg-color: var(--clr-bg-light);
  }

  &.is-na {
    --text-color: var(--clr-gray);
    --border-color: var(--clr-gray);

    &:has(:checked) {
      --text-color: var(--clr-white);
      --bg-color: var(--clr-gray);
    }
  }

  input {
    @include visually-hidden;

    &:checked {
      + .prod-option__label {
        background-color: var(--clr-black);
        color: var(--clr-white);
      }
    }

    &:disabled {
      + .prod-option__label {
        --bg-color: var(--clr-bg-light2);
        color: var(--clr-gray);
        border-color: var(--clr-gray);
        cursor: not-allowed;
      }

      &:checked {
        + .prod-option__label {
          background-color: var(--clr-gray);
        }
      }
    }
  }

  &__label {
    display: block;
    font-weight: var(--font-weight-semibold);
    font-size: var(--font-size-sm);
    line-height: 1.125em;
    padding: var(--gap-xs) var(--gap-md);
    border-radius: var(--radius-sm);
    border: 1px solid var(--border-color, var(--clr-black));
    cursor: pointer;
    transition: var(--duration-sm);
    background-color: var(--bg-color, transparent);

    @include media-down(md) {
      padding: var(--gap-xs) var(--gap-sm);
    }

    @include media-up(md) {
      padding: var(--gap-xs) var(--gap-md);
    }

    em {
      color: var(--clr-gray);
      font-style: normal;
      font-weight: normal;
    }
  }
}

.prod-cost,
.pre-order {
  padding: var(--gap-md) var(--gap-xl);
  background-color: var(--clr-bg-light);

  @include media-down(sm) {
    margin-left: calc(var(--gap-lg) * -1);
    margin-right: calc(var(--gap-lg) * -1);
  }

  @include media-up(sm) {
    border-radius: var(--radius-sm);
  }
}

.prod-cost {
  &__wrap {
    display: grid;
    gap: var(--gap-md);

    @include media-down(sm) {
      grid-template-columns: 100%;
      text-align: center;
    }

    @include media-up(sm) {
      grid-template-columns: 1fr auto;
      align-items: end;
    }
  }

  &__price {
    &-changes {
      display: flex;
      align-items: baseline;
      gap: var(--gap-xs);
      font-size: var(--font-size-sm);
      font-weight: var(--font-weight-semibold);

      @include media-down(sm) {
        justify-content: center;
      }
    }

    &-discount {
      color: var(--clr-primary);
    }

    &-old {
      color: var(--clr-gray);

      text-decoration: line-through;
    }

    &-new {
      font-weight: bold;

      @include media-down(sm) {
        font-size: var(--font-size-block-title-min);
      }

      @include media-up(sm) {
        font-size: var(--font-size-block-title-max);
      }
    }
  }

  &__parts {
    font-weight: var(--font-weight-medium);

    @include media-up(sm) {
      grid-row: 2/3;
      grid-column: 1/2;
    }

    &-label {
      font-size: var(--font-size-sm);
      margin-bottom: var(--gap-2xs);
    }

    &-value {
      display: flex;
      align-items: center;
      gap: var(--gap-xs);
      font-size: var(--font-size-lg);

      @include media-down(sm) {
        justify-content: center;
      }
    }
  }

  &__cheaper {
    @include media-up(sm) {
      grid-row: 2/3;
      grid-column: 2/3;
    }
  }

  &__in-cart {
    text-align: right;

    @include media-down(sm) {
      .btn {
        width: 100%;
      }
    }

    @include media-up(sm) {
      grid-row: 1/2;
      grid-column: 2/3;
    }
  }
}

.pre-order {

  &__wrap {
    display: grid;

    @include media-down(sm) {
      grid-template-columns: 100%;
      row-gap: var(--gap-sm);
    }

    @include media-up(sm) {
      grid-template-columns: repeat(2, 1fr);
      align-items: center;
      column-gap: var(--gap-md);
      row-gap: var(--gap-xs);
    }
  }

  &__text {
    font-size: var(--font-size-sm);

    @include media-up(sm) {
      grid-column: 1/2;
    }
  }

  &__button {
    text-align: right;

    @include media-down(sm) {
      .btn {
        width: 100%;
      }
    }

    @include media-up(sm) {
      grid-row: 1/2;
      grid-column: 2/3;
    }
  }
}

.prod-discount {
  padding: var(--gap-sm) var(--gap-md);
  background-color: var(--clr-bg-light);

  &__wrap {
    display: grid;
    grid-template-columns: var(--icon-xl) 1fr;
    gap: var(--gap-sm);
  }

  &__info {
    font-size: var(--font-size-sm);
    line-height: 120%;
  }

  &__text {
    color: var(--clr-gray-dark);
  }
}

.prod-delivery {
  &--moscow &__item--moscow,
  &--spb &__item--spb,
  &--regions &__item--regions {
    order: 1;
  }

  &__wrap {
    display: flex;
    column-gap: var(--gap-2xl);
    row-gap: var(--gap-md);

    @include media-down(lg) {
      flex-wrap: wrap;
    }
  }

  &__item {
    flex-grow: 1;
    font-size: var(--font-size-sm);
    line-height: var(--font-size-lg);
    order: 2;

    &--spb {
      white-space: nowrap;
    }
  }

  &__label {
    button {
      line-height: inherit;
    }
  }

  &__value {
    font-weight: var(--font-weight-semibold);

    button {
      font-weight: inherit;
      border-bottom: 1px dashed;
    }
  }

  &__ctrl {
    flex-shrink: 0;
    order: 3;
  }
}

.prod-info {
  &__wrap {
    display: flex;
    flex-direction: column;
    gap: var(--gap-xl);
  }
}

.prod-advantages {
  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    gap: var(--gap-md);
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__item {
    position: relative;
  }

  &__btn {
    display: grid;
    grid-template-columns: var(--icon-xl) 1fr;
    gap: var(--gap-sm);
    align-items: center;
  }

  &__label {
    font-size: var(--font-size-sm);
    font-weight: var(--font-weight-bold);
    border-bottom: 1px dashed;
  }
}

.prod-desc {
  font-size: var(--font-size-sm);

  &__list {
    display: flex;
    flex-direction: column;
    gap: var(--gap-md);
    margin: 0 0 var(--gap-md);
  }

  &__term {
    display: inline;

    &::after {
      content: ':';
    }
  }

  &__details {
    display: inline;
    font-weight: var(--font-weight-medium);
    margin: 0;
  }

  &__text {
    --clamp-max-height: 33px;

    p {
      margin: 0;

      &:not(:last-child) {
        margin-bottom: 1em;
      }
    }

    a {
      --text-link-color: hsl(var(--clr-black-hsl) / 40%);
      position: relative;

      &:hover {
        --text-link-color: hsl(var(--clr-black-hsl) / 80%);
      }

      &::after {
        position: absolute;
        content: '';
        width: 100%;
        height: 1px;
        background-color: var(--text-link-color);
        bottom: 0;
        left: 0;
        transition: background-color var(--duration-sm);
      }
    }
  }

  &__more {
    margin-top: var(--gap-md);
  }
}

.prod-pair {
  &__label {
    color: var(--clr-gray);
    font-size: var(--font-size-xs);
    font-weight: var(--font-weight-medium);
    margin-bottom: var(--gap-xs);
  }

  &__list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    gap: var(--gap-xs);
  }
}

.modal-product {
  display: flex;
  flex-direction: column;
  gap: var(--gap-2xl);

  &__top {
    display: flex;
    flex-direction: column;
    gap: var(--gap-sm);
  }

  &__title {
    line-height: 1.2;

    @include media-down(sm) {
      font-size: var(--font-size-modal-title-min);
    }

    @include media-up(sm) {
      font-size: var(--font-size-modal-title-max);
    }

    span {
      font-weight: var(--font-weight-medium);
    }
  }
}

.prod-advantage {
  display: flex;
  flex-direction: column;
  gap: var(--gap-md);

  &__top {
    line-height: 1.6;

    a {
      text-decoration: underline;
    }
  }

  &__main {
    &--grid {
      display: grid;
      gap: var(--gap-xl);

      @include media-up(sm) {
        grid-template-columns: repeat(2, max-content);
      }
    }
  }

  &__label {
    font-weight: var(--font-weight-semibold);
    margin-bottom: var(--gap-sm);
  }

  &__list {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  &__item {
    &:not(:last-child) {
      margin-bottom: var(--gap-2xs);
    }
  }
}

.discount-sm {
  --bg-color: var(--clr-primary);
  display: flex;
  align-items: center;
  gap: var(--gap-sm);
  font-size: var(--font-size-sm);

  &--3,
  &--4 {
    --bg-color: #FFD178;
  }

  &--5 {
    --bg-color: #FFBE8E;
  }

  &--6,
  &--7 {
    --bg-color: #FFA781;
  }

  &--10 {
    --bg-color: #FF978F;
  }

  &--15 {
    --bg-color: #FF8190;
  }

  &__value {
    height: var(--icon-xl);
    width: calc(var(--icon-2xl) + var(--gap-2xs));
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--clr-white);
    background-color: var(--bg-color);
    border-radius: var(--radius-sm);
    font-weight: var(--font-weight-bold);
  }

  &__label {
    font-weight: var(--font-weight-medium);
  }
}

.gift-sm {
  display: flex;
  align-items: center;
  gap: var(--gap-2xs);
  font-size: var(--font-size-sm);
  padding: var(--gap-2xs) 0;

  &__cover {
    flex-shrink: 0;
    color: var(--clr-primary);
  }
}
