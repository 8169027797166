.attach-box {
  &.dropzone {
    min-height: 100px;
    border: 1px solid var(--clr-border-input);
    background-color: var(--clr-bg-light);
    border-radius: var(--radius-sm);
    padding: var(--gap-sm);
    display: flex;
    flex-wrap: wrap;
    gap: var(--gap-sm);
    color: var(--clr-gray);

    &:not(.dz-started) {
      justify-content: center;
      align-items: stretch;
      padding: 0;

      .dz-message {
        margin: 0;
        display: flex;
        flex-grow: 1;

        button {
          flex-grow: 1;
          padding: 0 var(--gap-md);
        }
      }
    }

    .dz-preview {
      min-height: var(--photo-md);
      margin: 0;
      box-shadow: var(--clr-gray) 1px 1px 4px 0;

      .dz-error-message {
        top: 90px;
        font-size: var(--font-size-xs);
        padding: 0.4em 0.8em;
        width: 120px;
        text-align: center;
        left: 50%;
        transform: translateX(-50%);

        &::after {
          left: 50%;
          transform: translateX(-50%);
        }
      }

      .dz-details {
        display: none;
      }

      .dz-progress {
        --width: var(--icon-xl);
        --height: var(--icon-2xs);

        border: 1px solid white;
        width: var(--width);
        height: var(--height);
        margin-left: calc(-0.5 * var(--width));
        margin-top: calc(-0.5 * var(--height));
        border-radius: calc(0.5 * var(--height));

        .dz-upload {
          background: var(--clr-primary);
        }
      }

      .dz-image {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0;
        width: var(--photo-md);
        height: var(--photo-md);

        img {
          max-width: 100%;
          max-height: 100%;
        }
      }

      &:hover {
        .dz-image {
          img {
            filter: none;
            transform: none;
          }
        }
      }

      .dz-success-mark,
      .dz-error-mark {
        width: 54px;
        height: 54px;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          width: var(--icon-xl);
          height: var(--icon-xl);
        }
      }

      .dz-remove {
        position: absolute;
        top: 0;
        right: 0;
        display: block;
        width: var(--icon-sm);
        height: var(--icon-sm);
        cursor: pointer;
        text-indent: -9999px;
        color: var(--clr-gray);
        border: 0;
        background-color: transparent;
        z-index: 10;

        &:before,
        &:after {
          position: absolute;
          top: 50%;
          left: 50%;
          width: 12px;
          height: 2px;
          margin-top: -1px;
          margin-left: -6px;
          content: "";
          background-color: currentColor;
        }

        &:before {
          transform: rotate(45deg);
        }

        &:after {
          transform: rotate(-45deg);
        }
      }
    }
  }

  &.dz-clickable {
    cursor: pointer;
  }
}
