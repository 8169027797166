.slide-up-widget {
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  bottom: -15px;
  left: 0;
  right: 0;
  height: 95%;
  max-width: 100vw;
  background: #fff;
  z-index: 15000;
  color: #000;
  padding: 20px 15px 30px;
  font-weight: 700;
  border-radius: 25px 25px 0 0;
  box-shadow: 0 0 10px 0 #0006;
  font-size: 16px;
  font-family: "Helvetica Neue", Helvetica, sans-serif;
  transform: translateY(100%);
  transition: 300ms transform;

  &.show {
    transform: translateY(0);
  }

  &__header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-weight: 400;
    gap: 10px;
  }

  &__title {
    text-decoration: none;
    margin: 20px 10px 10px;
    font-size: 13px
  }

  &__header-close-btn {
    @extend .modal__close;
    top: -5px !important;
    right: -5px !important;
  }

  &__header-image {
    width: 100px;
    border-radius: 10px;
    height: 50px;
  }

  &__header-text {
    max-width: 240px;
    text-align: center;
    line-height: 20px;
    padding: 5px 0;
  }

  &__bank {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: #f4f4f4;
    padding: 10px;
    border-radius: 15px;
    color: #000;
    gap: 10px;
    height: 70px;
  }

  &__bank--hide,
  &__title--hide {
    display: none
  }

  &__bank-logo {
    display: block;
    max-width: 50px;
    border-radius: 10px;
    width: 50px;
    height: 50px;
    opacity: 1;
    transition: 300ms opacity;

    &:not(.loaded) {
      opacity: 0;
    }
  }

  &__input {
    width: 100%;
    background-color: #f4f4f4;
    padding: 15px 40px;
    outline: 0;
    border: none;
    border-radius: 10px;

    background-repeat: no-repeat;
    background-position: 11px center;
    background-size: 20px 20px;
    //background-image: url('../img/icon_search-clr.svg');
  }

  &__empty {
    font-size: 12px;
    font-weight: 400;
    padding: 0 10px
  }
}
