:root {
  --duration-sm: .24s;
  --duration-md: .48s;
  --duration-lg: .64s;

  --opacity-inactive: .32;
}

@keyframes spinner {
  100% {
    transform: rotate(360deg)
  }
}

@keyframes glowing {
  0% {
    box-shadow: 0 0 8px 1px var(--bg-color);
  }

  50% {
    box-shadow: 0 0 0 var(--bg-color);
  }

  100% {
    box-shadow: 0 0 8px 1px var(--bg-color);
  }
}

@keyframes reveal {
  to {
    opacity: 1;
  }
}
