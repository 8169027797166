.list {
  --gap-size: var(--gap-xs);
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: var(--gap-size);

  &--sm {
    --gap-size: var(--gap-sm);
  }

  &--md {
    --gap-size: var(--gap-md);
  }

  &--lg {
    --gap-size: var(--gap-lg);
  }

  &--xl {
    --gap-size: var(--gap-xl);
  }

  &--2xl {
    --gap-size: var(--gap-2xl);
  }

  &--3xl {
    --gap-size: var(--gap-3xl);
  }

  &--4xl {
    --gap-size: var(--gap-4xl);
  }

  &--bordered {
    .list__item:not(:last-child) {
      padding-bottom: var(--gap-size, var(--gap-xs));
      border-bottom: 1px solid var(--clr-border-light);
    }
  }

  &--margin-bottom {
    margin-bottom: var(--gap-size);
  }

  &--margin-top {
    margin-top: var(--gap-size);
  }

  &--dotted {
    list-style: circle;
  }

  &--align-right {
    text-align: right;
  }

  &--column-2 {
    column-count: 2;
    column-gap: var(--gap-3xl);
  }
}
