.btn {
  display: inline-grid;
  vertical-align: middle;
  grid-auto-flow: column;
  align-items: center;
  justify-content: center;
  gap: var(--gap-xs);
  text-decoration: none;
  border-radius: var(--radius-sm);
  transition: var(--duration-sm);
  text-align: left;

  &[data-tooltip] {
    position: relative;

    &:hover {
      &::after {
        display: block;
      }
    }

    &::after {
      display: none;
      position: absolute;
      content: attr(data-tooltip);
      font-size: var(--font-size-xs);
      background-color: var(--clr-white);
      padding: var(--gap-xs);
      border-radius: var(--radius-sm);
      z-index: 8;
      top: 100%;
      margin-top: var(--gap-xs);
      min-width: 10rem;
    }
  }

  &:disabled,
  &.is-disabled {
    cursor: not-allowed;
    pointer-events: none;
  }

  &--xs {
    --shadow-size: var(--gap-sm);
    padding: 0 var(--gap-xs);
    min-height: var(--control-height-xs);
    font-size: var(--font-size-xs);
  }

  &--s {
    --shadow-size: var(--gap-md);
    padding: 0 var(--gap-sm);
    min-height: var(--control-height-sm);
    font-size: var(--font-size-sm);
  }

  &--m {
    --shadow-size: var(--gap-lg);
    height: var(--control-height-md);

    @include media-down(md) {
      padding: 0 var(--gap-sm);
      font-size: var(--font-size-sm);
    }

    @include media-up(md) {
      padding: 0 var(--gap-lg);
    }
  }

  &--l {
    --shadow-size: var(--gap-xl);
    padding: 0 var(--gap-xl);
    height: var(--control-height-lg);
    font-size: var(--font-size-lg);
  }

  &--icon {
    min-width: auto;
    min-height: auto;

    &.btn--s,
    &.btn--m,
    &.btn--link {
      padding: 0;
    }

    &.btn--s {
      height: var(--control-height-xs);
      width: var(--control-height-xs);
    }

    &.btn--m {
      height: var(--control-height-sm);
      width: var(--control-height-sm);
    }

    &.btn--link {
      width: auto;
      height: auto;
    }
  }

  &--primary {
    --bg-color: var(--clr-primary);
    --bg-color-hover: var(--clr-black);
    --bg-color-second: hsl(var(--clr-white-hsl) / 20%);
    --text-color: var(--clr-white);
  }

  &--gray {
    --bg-color: var(--clr-gray);
    --bg-color-hover: var(--clr-gray-dark);
    --bg-color-second: hsl(var(--clr-white-hsl) / 20%);
    --text-color: var(--clr-white);
  }

  &--white {
    --bg-color: var(--clr-white);
    --bg-color-hover: var(--clr-bg-light2);
    --bg-color-second: hsl(var(--clr-primary-hsl) / 20%);
    --border-color-hover: var(--clr-white);
    --text-color: var(--clr-black);
  }

  &--black {
    --bg-color: var(--clr-black);
    --bg-color-second: hsl(var(--clr-white-hsl) / 20%);
    --bg-color-hover: var(--clr-primary);
    --text-color: var(--clr-white);

    &.is-inactive {
      .btn__label {
        color: var(--clr-gray);
      }
    }

    &.is-active {
      background-color: var(--bg-color);
      color: var(--clr-white);
    }
  }

  &--1 {
    color: var(--text-color, var(--clr-white));
    background-color: var(--bg-color);
    border: 1px solid var(--bg-color);

    &:hover {
      background-color: var(--bg-color-hover, var(--bg-color));
      color: var(--text-color-hover, var(--text-color));
    }

    &:disabled,
    &.is-disabled,
    &.is-inactive {
      color: var(--clr-gray);
      border-color: var(--clr-gray);
      background-color: transparent;
    }
  }

  &--2 {
    color: var(--bg-color);
    border: 1px solid var(--bg-color);
    background-color: var(--text-color, var(--clr-white));
    font-weight: var(--font-weight-medium);

    &:hover {
      border-color: var(--bg-color-hover, var(--bg-color));
      color: var(--text-color-hover, var(--text-color));

      @media (hover: none) {
        background-color: var(--bg-color-hover, var(--bg-color));
      }

      @media (hover: hover) {
        box-shadow: inset 0 0 0 var(--shadow-size) var(--bg-color-hover, var(--bg-color));
      }
    }

    &:disabled,
    &.is-disabled,
    &.is-inactive {
      color: var(--clr-gray-light);
      border-color: var(--clr-gray-light);
      background-color: var(--clr-bg-light);

      &:hover {
        box-shadow: none;
      }
    }
  }

  &--3 {
    color: var(--bg-color);
    background-color: transparent;
    padding: 0;
    height: var(--gap-md);
    border-radius: 0;
    gap: var(--gap-3xs);

    &:disabled,
    &.is-disabled,
    &.is-inactive {
      color: var(--clr-gray);
      background-color: transparent;
    }
  }

  &--4 {
    color: var(--bg-color);
    font-weight: var(--font-weight-medium);

    &:hover,
    &.is-pressed {
      color: var(--bg-color-hover, var(--bg-color));
    }

    &:disabled,
    &.is-disabled,
    &.is-inactive {
      color: var(--clr-gray);
      border-color: var(--clr-gray);
      background-color: transparent;
    }
  }

  &--link,
  &--link-underline,
  &--link-dashed {
    font-weight: var(--font-weight-medium);
    border-radius: 0;

    @include media-down(md) {
      padding: 0;
    }

    @include media-up(md) {
      padding: 0;
    }

    &:active {
      background-color: transparent;
    }
  }

  &--link-underline,
  &--link-dashed {
    height: auto;
  }

  &--link-underline {
    text-decoration: underline;
  }

  &--link-dashed {
    border-bottom: 1px dashed currentColor;
  }

  &--glowing {
    animation: glowing 1s ease-out infinite;
  }

  &--loading {
    --stripe-size: var(--gap-sm);
    pointer-events: none;
    background-size: 1000px 64px;
    background-image: repeating-linear-gradient(-45deg,
      var(--bg-color-second), var(--bg-color-second) var(--stripe-size),
      transparent var(--stripe-size), transparent calc(var(--stripe-size) * 2),
      var(--bg-color-second) calc(var(--stripe-size) * 2));
    background-repeat: repeat;
    animation: loading .8s linear infinite;
    opacity: 0.9;

    @keyframes loading {
      from {
        background-position: calc(-2.8 * var(--stripe-size)) 0;
      }
      to {
        background-position: 0 0;
      }
    }
  }

  &__label {
    position: relative;
    z-index: 2;
  }

  &__qty {
    font-size: var(--font-size-xs);
    color: var(--clr-white);
    background-color: var(--clr-primary);
    width: var(--icon-md);
    line-height: var(--icon-md);
    border-radius: 50%;
    text-align: center;
  }

  &.wide {
    width: 100%;
  }
}

.back-btn {
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  color: var(--clr-primary);
  gap: var(--gap-2xs);

  &__icon {
    flex-shrink: 0;
  }
}

.circle-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: text-bottom;
  color: var(--clr-black);
  min-width: 1em;
  min-height: 1em;
  border: 1px solid currentColor;
  border-radius: calc(infinity * 1px);
  margin-left: .16em;

  &__label {
    font-size: .8em;
    line-height: 1;
    display: block;
  }
}
