.cert-registration {
  display: flex;
  gap: var(--gap-2xl);

  @include media-down(md) {
    flex-direction: column;
  }

  &__cover {
    position: sticky;
    top: var(--gap-md);

    img {
      border-radius: var(--radius-xl);
    }

    @include media-up(md) {
      flex-shrink: 0;
    }
  }

  &__side {
    flex-shrink: 0;
  }

  &__main {
    flex-grow: 1;
  }

  &__body {
    display: flex;
    flex-direction: column;
    gap: clamp(var(--gap-3xl), 6vw, var(--gap-5xl));
  }

  &__block {
    display: flex;
    flex-direction: column;
    gap: var(--gap-lg);
  }

  &__footer {
    margin-top: clamp(var(--gap-3xl), 6vw, var(--gap-5xl));
    padding-top: clamp(var(--gap-md), 6vw, var(--gap-xl));
    border-top: 1px solid var(--clr-border-input);
    display: flex;
    flex-direction: column;
    gap: clamp(var(--gap-md), 6vw, var(--gap-xl));
  }

  &__sum {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    column-gap: var(--gap-lg);
    row-gap: var(--gap-sm);
    flex-wrap: wrap;

    &-label {
      font-size: var(--font-size-sm);
      font-weight: var(--font-weight-semibold);
    }

    &-value {
      font-size: clamp(var(--font-size-2xl), 4vw, var(--font-size-4xl));
      font-weight: var(--font-weight-bold);
    }
  }
}
