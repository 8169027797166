.more-link {
  font-weight: var(--font-weight);
  font-size: var(--font-size-sm);
  display: inline-flex;
  gap: var(--gap-xs);
  align-items: center;

  &--strong {
    --font-weight: var(--font-weight-semibold);
  }

  &--black {
    --color: inherit;
  }

  &--gray {
    --color: var(--clr-gray);

    &:hover {
      --color: var(--clr-black);
    }
  }

  &__label {
    transition: color var(--duration-sm);
    color: var(--color);
  }

  &__icon {
    transition: color var(--duration-sm);
    color: var(--icon-color, var(--color));
  }

  &:hover {
    --icon-color: var(--clr-primary);
  }
}
