.article-line {
  &__wrap {
    display: flex;
    gap: clamp(var(--gap-lg), 3.2vw, var(--gap-3xl));

    @include media-down(sm) {
      flex-direction: column;
    }
  }

  &__cover {
    @include media-up(sm) {
      flex-shrink: 0;
      flex-basis: clamp(240px, 32vw, 400px);
    }

    img {
      width: auto;
      border-radius: var(--radius-sm);
    }
  }

  &__main {
    display: flex;
    flex-direction: column;
    gap: var(--gap-xs);
  }

  &__time {
    font-size: var(--font-size-xs);
    font-weight: var(--font-weight-medium);
    color: var(--clr-gray);
  }

  &__title {
    font-weight: var(--font-weight-bold);
    font-size: clamp(var(--font-size-lg), 1.6vw, var(--font-size-xl));;
    margin: 0;
  }

  &__text {
    p {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

  &__more {
    font-size: var(--font-size-sm);
    font-weight: var(--font-weight-semibold);
    margin-top: auto;
  }
}
