.page-top {
  &__wrap {
    &--side {
      display: grid;
      gap: clamp(var(--gap-lg), 3.2vw, var(--gap-3xl));

      @include media-up(lg) {
        grid-template-columns: var(--side-size) 1fr;
      }

      @include media-only(lg) {
        --side-size: var(--side-size-sm);
      }

      @include media-up(xl) {
        --side-size: var(--side-size-lg);
      }
    }
  }

  &__head {
    --gap: var(--gap-md);
    display: flex;
    gap: var(--gap);
    margin-bottom: var(--gap);

    @include media-down(sm) {
      flex-direction: column;
    }

    @include media-up(sm) {
      align-items: center;
    }
  }

  &__title {
    flex-grow: 1;

    @include media-down(sm) {
      text-align: center;
    }
  }

  &__control {
    flex-shrink: 0;
  }

  &__body {
    --clamp-max-height: 93px;

    a {
      text-decoration: underline;
    }
  }

  &__more {
    margin-top: var(--gap-sm);
  }
}
