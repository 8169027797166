.swiper-wrapper {
  margin: 0;
  padding: 0;
  list-style: none;
  height: auto;
}

.fourth-slider {
  &__slide {
    display: flex;
    flex-direction: column;
    padding: 2px;

    > * {
      flex-grow: 1;
    }
  }
}

.brands-slider {
  &__wrap {
    transition-timing-function: linear;
  }

  &__slide {
    width: 212px;
    margin-top: var(--gap-xs);
    margin-bottom: var(--gap-xl);
  }
}

.discounts-slider {
  &__slide {
    display: inline-block;
    width: 180px;
  }

  &:not(.swiper-initialized) &__wrap {
    display: flex;
    gap: var(--gap-xl);
  }
}
