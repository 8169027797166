input[type='radio'],
input[type='checkbox'],
input[type='file'] {
  &:focus-visible {
    + * {
      outline: 1px auto var(--clr-primary);
      outline-offset: 4px;
    }
  }
}

.switch {

  &__main {
    display: inline-flex;
    vertical-align: middle;
    cursor: pointer;
    gap: var(--gap-size);
    position: relative;
  }

  &__extra {
    margin-top: var(--gap-xs);
    margin-left: calc(var(--cover-size) + var(--gap-size));
    display: flex;
    flex-direction: column;
    gap: var(--gap-xs);
  }

  &--disabled {
    cursor: not-allowed;
    --label-color: var(--clr-gray);
  }

  input {
    position: absolute;
    width: var(--cover-size);
    height: var(--cover-size);
    border: 0 none;
    background-color: transparent;
    margin: 0;
    padding: 0;
    left: 0;
    top: 0;

    &:disabled {
      + .switch__cover {
        --color-border: var(--clr-border-input-hover);
        --color-bg: hsl(var(--clr-gray-h), var(--clr-gray-s), 98%);
      }

      ~ .switch__label {
        --label-color: var(--clr-gray);
      }
    }

    &:disabled:checked {
      + .switch__cover {
        background-color: hsl(var(--clr-gray-h), var(--clr-gray-s), 98%);
        border-color: var(--clr-border-input-hover);

        svg {
          color: var(--clr-gray);
        }

        &::after {
          background-color: var(--clr-gray);
        }
      }
    }
  }

  &__icon {
    flex-shrink: 0;
  }

  /* &:not(.switch--disabled):hover {
    .switch__cover {
      border-color: var(--clr-border);
    }
  } */

  &__cover {
    position: relative;
    display: inline-flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    content: '';
    border: 1px solid var(--color-border, var(--clr-black));
    transition: var(--duration-sm);
    background-color: var(--color-bg, var(--clr-white));

    svg {
      opacity: 0;
      transition: var(--duration-sm);
    }
  }

  &__tooltip {
    margin-left: var(--gap-2xs);

    .tooltip__btn {
      width: var(--cover-size);
      height: var(--cover-size);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__label {
    font-size: var(--font-size);
    font-weight: var(--font-weight-medium);
    min-height: var(--cover-size);
    line-height: var(--cover-size);
    color: var(--label-color, var(--clr-black));
    flex-grow: 1;
    display: flex;
    align-items: center;
    transition: color var(--duration-sm);

    strong {
      font-weight: 500;
    }

    &--sm {
      --font-size: var(--font-size-sm);
    }

    &--md {
      --font-size: var(--font-size-base);
      margin-left: var(--gap-md);
      padding-top: 2px;
    }

    &--lg {
      --font-size: var(--font-size-lg);
      margin-left: var(--gap-sm);
      padding-top: 0;
    }
  }

  &__note {
    display: inline-block;
    font-style: normal;
    font-size: var(--font-size-sm);
    line-height: 1.2;
    color: var(--clr-gray);
    margin-top: var(--gap-3xs);
  }
}

.checkbox,
.radio,
.toggle {
  --color-border: var(--clr-black);

  &--s {
    --cover-size: var(--icon-sm);
    --font-size: var(--font-size-sm);
    --top-indent: -1px;
    --gap-size: var(--gap-xs);
  }

  &--es {
    --cover-size: var(--icon-md);
    --font-size: var(--font-size-sm);
    --top-indent: 0;
    --gap-size: var(--gap-sm);
  }

  &--m {
    --cover-size: var(--icon-lg);
    --font-size: var(--font-size-base);
    --top-indent: 2px;
    --gap-size: var(--gap-sm);
  }

  &--l {
    --cover-size: var(--icon-xl);
    --font-size: var(--font-size-lg);
    --top-indent: 4px;
    --gap-size: var(--gap-md);
  }
}

.checkbox,
.radio {
  &__cover {
    width: var(--cover-size);
    height: var(--cover-size);
    flex-shrink: 0;
  }
}

.checkbox {
  input {
    &:checked {
      + .checkbox__cover {
        background-color: var(--bg-cover, var(--clr-primary));
        border-color: var(--bg-cover, var(--clr-primary));
        color: var(--clr-white);

        .svg {
          opacity: 1;
        }
      }
    }
  }

  &__cover {
    color: var(--clr-white);
    border-radius: var(--radius-sm);
  }
}

.radio {
  &--s {
    --icon-size: 6px;
  }

  &--m {
    --icon-size: 10px;
  }

  &--l {
    --icon-size: 12px;
  }

  input {
    &:checked {
      + .radio__cover {
        background-color: var(--clr-primary);
        border-color: var(--clr-primary);

        .svg {
          opacity: 1;
        }
      }
    }
  }

  &__cover {
    border-radius: 50%;
    color: var(--clr-white);
  }
}

.toggle {
  &--s {
    --icon-size: 12px;
  }

  &--m {
    --icon-size: 18px;
  }

  &--l {
    --icon-size: 22px;
  }

  input {
    &:checked {
      + .toggle__cover {
        background-color: var(--clr-primary);
        border-color: var(--clr-primary);

        &::after {
          background-color: var(--clr-white);
          left: calc(100% - var(--icon-size) - 2px);
        }
      }
    }

    &:disabled {
      + .toggle__cover {
        &::after {
          background-color: hsl(214, 12%, 88%);
        }
      }
    }
  }

  &__cover {
    width: calc(var(--cover-size) * 2);
    height: var(--cover-size);
    border-radius: calc(var(--cover-size) / 2);

    &::after {
      position: absolute;
      content: '';
      width: var(--icon-size);
      height: var(--icon-size);
      border-radius: 50%;
      background-color: var(--clr-black);
      top: 50%;
      margin-top: calc(var(--icon-size) / 2 * -1);
      left: 2px;
      transition: var(--duration-sm);
    }
  }
}
