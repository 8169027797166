.g-cert-teas {
  display: block;
  position: relative;
  margin: var(--gap-xl) 0;

  &__cover {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__main {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: var(--gap-md) 0;
    z-index: 2;

    @include media-down(sm) {
      max-width: 200px;
      margin: 0 auto;
    }

    @include media-down(sm) {
      min-height: 162px;
    }

    @include media-up(sm) {
      min-height: 106px;
    }

    @include media-up(lg) {
      min-height: 158px;
    }
  }

  &__info {
    text-align: center;
  }

  &__title {
    font-weight: 500;

    @include media-down(sm) {
      font-size: 1.5em;
    }

    @include media-up(sm) {
      font-size: 2em;
    }

  }

  &__description {
    margin-top: 0.8em;
  }
}
