.row {
  --vertical-gap: var(--gap-sm);
  --horizontal-gap: var(--gap-lg);
  display: flex;
  flex-wrap: wrap;
  margin: calc(var(--vertical-gap) * -1) calc(var(--horizontal-gap) * -1);

  &--xs {
    --vertical-gap: var(--gap-2xs);
    --horizontal-gap: var(--gap-xs);
  }

  &--sm {
    --vertical-gap: var(--gap-xs);
    --horizontal-gap: var(--gap-md);
  }

  &--aic {
    align-items: center;
  }

  &--aife {
    align-items: flex-end;
  }
}

.col {
  flex-shrink: 1;
  flex-grow: 1;
  flex-basis: 100%;
  max-width: 100%;
  padding: var(--vertical-gap) var(--horizontal-gap);

  &--in {
    display: flex;
    flex-direction: column;
  }

  &--half {
    @include media-up(lg) {
      flex-basis: 50%;
      max-width: 50%;
    }
  }

  &--third {
    @include media-up(lg) {
      flex-basis: 33.333%;
      max-width: 33.333%;
    }
  }

  &--third-2 {
    @include media-up(lg) {
      flex-basis: 66.666%;
      max-width: 66.666%;
    }
  }

  &--fourth {
    @include media-up(lg) {
      flex-basis: 25%;
      max-width: 25%;
    }
  }

  &--fourth-3 {
    @include media-up(lg) {
      flex-basis: 75%;
      max-width: 75%;
    }
  }
}

.w-100 {
  width: 100%;
}

.grid {
  --template-columns: repeat(auto-fill, minmax(240px, 1fr));
  --column-gap: var(--gap-3xl);
  --row-gap: var(--gap-2xl);
  display: grid;
  grid-template-columns: var(--template-columns);
  column-gap: var(--column-gap);
  row-gap: var(--row-gap);
  justify-content: center;

  &--more {
    --favorite-label-display: none;
    --favorite-icon-padding: 0 var(--gap-xs);
    --prod-cover-height: 160px;
    --prod-fz-title: var(--font-size-sm);
    --prod-fz-price: var(--font-size-lg);
    --prod-bottom-justify: flex-start;
    --badge-flex-direction: row-reverse;
    --badge-label-padding-right: var(--gap-2xs);
    --badge-label-padding-left: calc(var(--gap-2xs) / 2);
    --badge-label-border-radius: 0 2px 2px 0;
    --badge-flag-transform: scale(-1, 1);
    --badge-flag-margin: 0 -1px 0 0;
    --stats-item-flex-direction: row-reverse;
    --stats-item-margin: 0 0 0 auto;
    --stats-item-padding-right: calc(var(--gap-2xs) / 2);
    --volume-gap-v: calc(var(--gap-2xs) / 2);
    --volume-gap-h: var(--gap-2xs);

    @include media-down(xs) {
      --template-columns: repeat(auto-fill, minmax(152px, 1fr));
      --column-gap: var(--gap-lg);
    }

    @include media-up(xs) {
      --template-columns: repeat(auto-fill, minmax(172px, 1fr));
      --column-gap: var(--gap-2xl);
    }
  }

  &--half {
    --template-columns: repeat(auto-fill, minmax(clamp(280px, 32vw, 620px), 1fr));
  }

  &--half-modal {
    --column-gap: var(--gap-xl);
    --row-gap: var(--gap-xl);
    --template-columns: repeat(auto-fill, minmax(clamp(280px, 24vw, 440px), 1fr));
  }

  &--third {
    --template-columns: repeat(auto-fill, minmax(clamp(280px, 24vw, 400px), 1fr));
  }

  &--fifth {
    --column-gap: var(--gap-2xl);
    --row-gap: var(--gap-xl);
    --template-columns: repeat(auto-fill, minmax(120px, 148px));
  }
}
