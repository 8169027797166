.out-page {
  padding: var(--gap-4xl) 0;

  &__wrap {
    display: flex;
    flex-direction: column;
    gap: var(--gap-4xl);
  }

  &__top {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--gap-4xl);
    text-align: center;
  }

  &__title {
    line-height: 120%;
  }
}
