.tabs {
  position: relative;
  width: 100%;

  &--sm {
    --font-size-min: var(--font-size-sm);
    --font-size-max: var(--font-size-sm);
    --font-size-em: var(--font-size-2xs);
    --gap-items-size: clamp(var(--gap-md), 2.4vw, var(--gap-lg));
    --gap-link-size: var(--gap-xs);
  }

  &--md {
    --font-size-min: var(--font-size-sm);
    --font-size-max: var(--font-size-base);
    --gap-items-size: var(--gap-xl);
    --gap-items-size: clamp(var(--gap-lg), 2.8vw, var(--gap-xl));
    --gap-link-size: var(--gap-sm);
  }

  &--lg {
    --font-size-min: var(--font-size-base);
    --font-size-max: var(--font-size-lg);
    --gap-items-size: clamp(var(--gap-xl), 3.2vw, var(--gap-2xl));
    --gap-link-size: var(--gap-md);
  }

  &__wrap {
    overflow-x: auto;
    scroll-behavior: auto;
    scrollbar-width: none;
    white-space: nowrap;
    display: flex;

    &::-webkit-scrollbar {
      display: none;
    }

    &::after {
      position: absolute;
      content: '';
      height: 100%;
      width: calc(var(--gap-items-size) * 4);
      right: -2px;
      bottom: 0;
      z-index: 2;
      pointer-events: none;
      background: linear-gradient(to left,
        hsl(var(--clr-white-h) var(--clr-white-s) var(--clr-white-l) / 1) 0%,
        hsl(var(--clr-white-h) var(--clr-white-s) var(--clr-white-l) / .9) 50%,
        hsl(var(--clr-white-h) var(--clr-white-s) var(--clr-white-l) / 0) 100%);
    }
  }

  &__list {
    list-style: none;
    margin: 0;
    padding: 2px 0;
    position: relative;
    flex-shrink: 0;
    margin-right: var(--gap-3xl);
  }

  &__item {
    display: inline-block;
    margin-right: var(--gap-items-size);

    &:last-child {
      margin-right: calc(var(--gap-items-size) * 2);
    }
  }

  &__link {
    display: flex;
    align-items: center;
    gap: var(--gap-link-size);
    transition: color var(--duration-sm);
    white-space: nowrap;
    text-align: left;

    &--na {
      color: var(--clr-gray);
    }

    &-label {
      position: relative;
      font-weight: var(--font-weight-medium);
      line-height: 1;
      padding: var(--gap-sm) 0;

      em {
        font-size: var(--font-size-em);
        font-style: normal;
      }

      &::before {
        position: absolute;
        content: '';
        bottom: 0;
        left: 0;
        right: 0;
        height: 2px;
        transition: var(--duration-sm);
        background-color: var(--border-active-color, transparent);
      }
    }

    @include media-down(lg) {
      font-size: var(--font-size-min);
    }

    @include media-up(lg) {
      font-size: var(--font-size-max);
    }

    &:hover {
      color: var(--clr-primary);
    }

    &.is-active {
      --border-active-color: var(--clr-primary);
    }
  }
}

.tab-content {
  display: none;

  &.is-active {
    display: block;
  }
}

.i-tabs {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;

  &__btn {
    display: block;
    padding: var(--gap-xs) var(--gap-2xs);
    position: relative;

    &.is-active {
      color: var(--clr-primary);

      &::before {
        position: absolute;
        content: '';
        bottom: 0;
        left: var(--gap-2xs);
        right: var(--gap-2xs);
        height: 2px;
        background-color: var(--clr-primary);
      }
    }
  }
}
