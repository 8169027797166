.delivery-method {
  --dm-gap-vertical: var(--gap-sm);
  --dm-gap-horizontal: clamp(var(--gap-sm), 3.2vw, var(--gap-lg));
  --dm-cover-width: 80px;
  --dm-top-align-items: center;
  --dm-top-margin-bottom: var(--gap-2xs);
  --dm-price-margin-bottom: 0;
  --dm-name-font-size: var(--font-size-base);
  --dm-price-font-size: var(--font-size-xl);
  --dm-price-margin-top: 0;
  position: relative;
  background-color: var(--clr-white);
  border-radius: var(--radius-sm);
  padding: var(--dm-gap-vertical) var(--dm-gap-horizontal);

  &:has(input:checked) {
    border-color: var(--clr-primary);
  }

  &--bordered {
    --dm-border-width: 1px;
    border: var(--dm-border-width) solid var(--clr-border-input);
  }

  &--sm {
    --dm-gap-vertical: 0;
    --dm-gap-horizontal: 0;
    --dm-cover-width: var(--photo-md);
    --dm-name-font-size: var(--font-size-sm);
    --dm-price-font-size: var(--font-size-base);
    --dm-top-margin-bottom: 0;
  }

  &__top {
    display: flex;
    align-items: var(--dm-top-align-items, flex-start);
    gap: var(--gap-md);
    margin-bottom: var(--dm-top-margin-bottom, var(--gap-sm));
  }

  &__cover {
    flex-shrink: 0;
    max-width: var(--dm-cover-width, none);
  }

  &__radio {
    input {
      top: var(--dm-gap-vertical);
      left: var(--dm-gap-horizontal);
      margin: 0;
    }

    label {
      position: unset;
      display: flex;

      &::before {
        position: absolute;
        content: '';
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }
    }
  }

  &__name {
    font-size: var(--dm-name-font-size, var(--font-size-xl));
    font-weight: var(--font-weight-semibold);
  }

  &__price {
    font-size: var(--dm-price-font-size, var(--font-size-3xl));
    font-weight: var(--font-weight-bold);
    margin-top: var(--dm-price-margin-top, var(--gap-2xs));
    margin-bottom: var(--dm-price-margin-bottom, var(--gap-xl));
  }
}
