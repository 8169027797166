.sitemap-list {

  @include media-up(sm) {
    column-count: 2;
    column-gap: var(--gap-3xl);
  }

  li {
    break-inside: avoid;
    page-break-inside: avoid;
    column-fill: auto;
    margin-bottom: var(--gap-sm);
  }

  ul {
    margin-top: var(--gap-sm);
  }

  a {
    transition: var(--duration-sm);
    display: block;
    padding: var(--gap-2xs) 0;

    &:hover {
      color: var(--clr-primary);
    }
  }
}
