.articles {
  display: grid;
  grid-template-columns: var(--columns);
  column-gap: clamp(var(--gap-xl), 4vw, var(--gap-3xl));
  row-gap: var(--gap-3xl);

  @include media-up(xs) {
    --columns: repeat(2, 1fr);
  }

  @include media-up(sm) {
    --columns: repeat(3, 1fr);
  }

  @include media-up(md) {
    --columns: repeat(4, 1fr);
  }

  &__item {
    @include media-down(sm) {
      &:nth-child(3) {
        display: none;
      }
    }
    @include media-down(md) {
      &:nth-child(4) {
        display: none;
      }
    }
  }
}
