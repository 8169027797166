.article {
  line-height: 180%;

  a {
    text-decoration: underline;
    color: inherit;
  }

  strong {
    font-weight: var(--font-weight-semibold);
  }

  p, ul, ol, div {
    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  blockquote {
    margin: 2em 0;
    padding: 1.6em 2em;
    background-color: var(--clr-bg-light);
    border-left: 4px solid var(--clr-border-input);
    font-weight: var(--font-weight-medium);
    font-style: italic;
  }

  ol,
  ul {
    padding-left: var(--gap-2xl);

    li {
      &:not(:last-child) {
        margin-bottom: 1em;
      }
    }
  }

  h2,
  h3 {
    margin-top: 2em;
    font-weight: var(--font-weight-semibold);
  }

  h2 {
    font-size: 1.5em;
  }

  h3 {
    font-size: 1.25em;
  }
}

.article-prods {
  margin-bottom: clamp(var(--gap-lg), 4vw, var(--gap-2xl));

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: clamp(var(--gap-sm), 4vw, var(--gap-lg));
  }
}

.article-prod {
  --gap: clamp(var(--gap-lg), 4vw, var(--gap-2xl));
  position: relative;
  padding: var(--gap);
  border: 1px solid var(--clr-border-input);
  border-radius: var(--radius-sm);
  display: flex;
  gap: var(--gap);

  @include media-down(sm) {
    flex-direction: column;
  }

  &__cover {
    position: relative;
    display: block;
    width: clamp(160px, 20vw, 200px);
    aspect-ratio: 1/1;
    flex-shrink: 0;
    @include img-center;
  }

  &__info {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  &__title {
    font-size: var(--font-size-xl);
    margin-bottom: var(--gap-xs);
    margin-right: var(--control-height-sm);

    a {
      text-decoration: none;
    }
  }

  &__favorite {
    position: absolute;
    top: var(--gap);
    right: var(--gap);
  }
}
