//screens
$screen-xl: 1360px;
$screen-lg: 1280px;
$screen-md: 1000px;
$screen-sm: 700px;
$screen-xs: 500px;

:root {
  --container-lg: calc(1280px + calc(var(--gap-lg) * 2));
  --container-md: calc(960px + calc(var(--gap-lg) * 2));
  --container-min: 320px;
  --container-max: 1920px;

  --logo-glyph-size-lg: 52px;
  --logo-glyph-size-md: 40px;
  --logo-glyph-size-sm: 24px;
  --header-min-height: 64px;

  --side-size-lg: 290px;
  --side-size-sm: 220px;

  --gap-2xs: 4px;
  --gap-xs: 8px;
  --gap-sm: 12px;
  --gap-md: 16px;
  --gap-lg: 20px;
  --gap-xl: 24px;
  --gap-2xl: 32px;
  --gap-3xl: 40px;
  --gap-4xl: 48px;
  --gap-5xl: 56px;
  --gap-6xl: 64px;

  --control-height-xs: 24px;
  --control-height-sm: 32px;
  --control-height-md: 40px;
  --control-height-lg: 48px;

  --radius-xs: 2px;
  --radius-sm: 4px;
  --radius-md: 6px;
  --radius-lg: 8px;
  --radius-xl: 12px;

  --icon-2xs: 8px;
  --icon-xs: 12px;
  --icon-sm: 16px;
  --icon-md: 20px;
  --icon-lg: 24px;
  --icon-xl: 32px;
  --icon-2xl: 40px;

  --photo-xs: 32px;
  --photo-sm: 48px;
  --photo-md: 64px;
  --photo-lg: 80px;
}
