.hero {
  position: relative;

  &__controls {
    position: absolute;
    width: 100%;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: var(--gap-md);
    margin-bottom: var(--gap-xl);
    z-index: 2;
  }

  &__pagination {
    display: flex;
    gap: var(--gap-md);

    .swiper-pagination-bullet {
      width: var(--gap-xs);
      height: var(--gap-xs);
      border-radius: 50%;
      background-color: var(--clr-gray);
      outline: var(--gap-2xs) solid hsl(var(--clr-white-h) var(--clr-white-s) var(--clr-white-l) / .7);
      transition: var(--duration-sm);

      &:hover {
        transform: scale(1.2);
        cursor: pointer;
      }

      &.swiper-pagination-bullet-active {
        background-color: var(--clr-black);

        &:hover {
          transform: scale(1);
          cursor: default;
        }
      }
    }
  }
}

.hero-slide {
  background: var(--bg-color, transparent);
  position: relative;

  &--left {
    @include media-up(sm) {
      --left-offset: 50%;
    }
  }

  &--right {
    @include media-up(sm) {
      --content-max-width: 640px;
    }
  }

  &--center {
    --left-offset: auto;
    --right-offset: auto;
    --text-align: center;
    --main-align-items: center;
    --content-max-width: 640px;
  }

  &--light {
    --button-bg: var(--clr-primary);
    --button-font-color: var(--clr-white);
  }

  &--dark {
    --font-color: var(--clr-white);
    --button-bg: var(--clr-white);
    --button-font-color: var(--clr-primary);
  }

  &__wrap {
    position: relative;
    max-width: var(--container-max);
    margin-left: auto;
    margin-right: auto;
  }

  &__visual {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    img,
    video {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
      margin: auto;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }

  &__main {
    position: relative;
    margin-left: var(--left-offset, 0);
    margin-right: var(--right-offset, 0);
    color: var(--font-color);
    display: flex;
    flex-direction: column;
    align-items: var(--main-align-items, flex-start);
    max-width: var(--content-max-width, 100%);
    text-align: var(--text-align, left);
    padding-top: var(--gap-2xl);
    padding-bottom: var(--gap-2xl);

    @include media-down(xs) {
      min-height: 480px;
    }

    @include media-up(xs) {
      min-height: 620px;
    }

    @include media-down(sm) {
      justify-content: var(--main-justify-content, flex-start);
    }

    @include media-up(sm) {
      justify-content: var(--main-justify-content, center);
    }
  }

  &__title {
    --font-size: clamp(32px, 4vw, 52px);
    font-family: var(--font-family-extra);
    font-size: var(--font-size);
    font-weight: 500;
    margin-top: 0;
    margin-bottom: .2em;

    b {
      font-family: var(--font-family-base);
      font-size: 124%;
      line-height: 1;
      color: var(--clr-primary-contrast);
    }
  }

  &__subtitle {
    margin: 0.4em 0;
    font-weight: var(--font-weight-medium);

    @include media-up(sm) {
      font-size: 2rem;
    }

    @include media-down(sm) {
      font-size: 1.5rem;
    }

    b {
      font-size: 124%;
      line-height: 1;
      color: var(--clr-primary-contrast)
    }
  }

  &__text {
    margin: 0;
    font-size: clamp(var(--font-size-base), 2vw, var(--font-size-xl));
  }

  &__btn {
    margin-top: clamp(1.25rem, 2.4vw, 2rem);
  }

  .btn--hero {
    color: var(--button-font-color);
    background-color: var(--button-bg);
  }
}
