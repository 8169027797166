.fund-box {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: clamp(var(--gap-lg), 3.2vw, var(--gap-xl));
  border-radius: var(--radius-sm);
  gap: var(--gap-lg);

  &--promo {
    --footnote-color: var(--clr-white);
    background-color: #FFA5BB;
    box-shadow: 0 16px 48px rgba(255, 165, 187, 0.32);
  }

  &--discount {
    background-color: #FFF5B0;
    box-shadow: 0 16px 48px rgba(255, 245, 176, 0.58);
  }

  &--bonus {
    background-color: #FFDDAD;
    box-shadow: 0 16px 48px rgba(255, 221, 173, 0.55);
  }

  &__top {
    display: flex;
    flex-direction: column;
    gap: var(--gap-2xs);
  }

  &__title {
    font-weight: var(--font-weight-semibold);
    font-size: var(--font-size-xl);
  }

  &__value {
    font-weight: var(--font-weight-bold);

    &--xl {
      font-size: 3rem;
    }
  }

  &__main {
    &--center {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    &--overflow {
      max-height: calc(calc(var(--icon-lg) * 5) + calc(var(--gap-sm) * 4));
      padding-right: var(--gap-2xs);
      margin-right: calc(var(--gap-xs) * -1);
      @include scrollbar-v;
    }
  }

  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: var(--gap-2xs);
  }

  &__bottom {
    margin-top: auto;
  }

  &__description {
    font-size: var(--font-size-sm);
  }

  &__cta {
    font-size: var(--font-size-xs);
  }

  &__footnote {
    font-size: var(--font-size-xs);
    color: var(--footnote-color);
  }

  &__btn {
    margin-top: var(--gap-md);
  }
}
