.b24-widget-button-wrapper.b24-widget-button-position-bottom-right {
  bottom: 63px;
  right: 0;
}

@media (max-width: 1279px) {
  .b24-widget-button-inner-container {
    transform: scale(0.6) !important;
  }
  .b24-widget-button-position-bottom-right .b24-widget-button-popup {
    bottom: 65px !important;
    right: 7px !important;
    left: auto !important;
  }
  .b24-widget-button-position-bottom-right .b24-widget-button-popup-triangle {
    right: 20px !important;
    transform: rotate(45deg) !important;
    bottom: -5px !important;
  }
}

@media (min-width: 1280px) {
  .b24-widget-button-wrapper.b24-widget-button-position-bottom-right {
    bottom: 15px;
    right: 15px;
  }
}

// save some battery power
.b24-widget-button-pulse-animate {
  animation-iteration-count: 40;
}
