.prod-teaser {
  --offset-size: var(--gap-xs);
  max-width: 320px;
  display: flex;
  flex-direction: column;
  padding-top: var(--offset-size);

  @media (hover: hover) {
    --opacity-bottom: 0;

    &:hover {
      --opacity-bottom: 1;
    }
  }

  &__wrap {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  &__main {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  &__visual {
    height: var(--prod-cover-height, 180px);
    transition: height var(--duration-sm);
    --delay-step: 75ms;
    --hover-animation-duration: 400ms;

    position: relative;
    margin-bottom: var(--gap-xl);

    &:hover {
      .prod-teaser__stats-item,
      .prod-teaser__badge {
        transform: translateX(70px);
        max-width: 38px;
        opacity: 0.5;
      }

      .stats-item__label,
      .badge__label {
        color: transparent;
      }
    }
  }

  &__cover {
    position: absolute;
    width: 100%;
    top: 0;
    bottom: 0;
    left: 0;

    &--video {
      overflow: hidden;
      border-radius: var(--radius-xs);

      img,
      video {
        pointer-events: none;
      }
    }

    > picture.img--fill:not(.img--loaded) {
      right: unset !important;
    }

    img,
    video {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      height: 100%;
      width: auto;
      border-radius: var(--radius-xs);
      object-fit: contain;
    }
  }

  &__info {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    width: 108px;
    pointer-events: none;
  }

  &__badges {
    display: flex;
    flex-direction: column;
    gap: var(--gap-2xs);
  }

  &__stats-item,
  &__badge {
    --delay: var(--group-delay, 0ms);

    transform: translateX(0);
    max-width: 100%;
    opacity: 1;
    transition: all var(--hover-animation-duration) ease var(--delay);

    &:nth-child(2) {
      --delay: calc(var(--group-delay, 0ms) + var(--delay-step));
    }

    &:nth-child(3) {
      --delay: calc(var(--group-delay, 0ms) + var(--delay-step) * 2);
    }
  }

  &__badge .badge__label,
  &__stats-item .stats-item__label {
    max-width: 100%;
    overflow: hidden;
    transition: color var(--hover-animation-duration) ease var(--delay, 0ms);
    white-space: nowrap;
  }

  &__stats {
    --group-delay: calc(var(--delay-step) * 3.25);

    margin-top: auto;
    display: flex;
    flex-direction: column;
    gap: var(--gap-2xs);
    margin-bottom: var(--gap-2xs);
  }

  &__dots {
    display: flex;
    align-items: center;
    gap: var(--gap-sm);
    position: absolute;
    bottom: calc(var(--gap-xl) * -1);
  }

  &__dot {
    width: var(--gap-xs);
    height: var(--gap-xs);
    border-radius: 50%;
    background-color: var(--clr-primary);

    &--active {
      background-color: var(--clr-black);
    }
  }

  &__body {
    margin-top: var(--gap-sm);
  }

  &__volumes {
    display: flex;
    gap: var(--gap-2xs);
    font-size: var(--font-size-2xs);
    margin-bottom: var(--gap-xs);
  }

  &__volume {
    padding: var(--volume-gap-v, var(--gap-2xs)) var(--volume-gap-h, var(--gap-xs));
    border: 1px solid var(--clr-gray);
    border-radius: var(--radius-sm);
    transition: var(--duration-sm);
    color: var(--clr-text);
    line-height: 120%;

    &:hover {
      border-color: var(--clr-primary);
      background-color: var(--clr-primary);
      color: var(--clr-white);
    }
  }

  &__text {
    line-height: var(--gap-lg);
  }

  &__title {
    transition: var(--duration-sm);
    line-height: inherit;
    font-size: var(--prod-fz-title, var(--font-size-base));
  }

  &__description {
    margin-top: var(--gap-lg);
    font-size: var(--font-size-sm);
    hyphens: auto;
  }

  &__prices,
  &__availability {
    margin-top: var(--gap-sm);
    margin-bottom: var(--gap-lg);
    line-height: var(--gap-lg);
  }

  &__prices {
    font-weight: bold;

    @include media-up(lg) {
      font-size: var(--prod-fz-price, var(--font-size-xl));
    }
  }

  &__availability {
    color: var(--clr-gray);
  }

  &__bottom {
    transition: var(--duration-md);
    opacity: var(--opacity-bottom);
    display: flex;
    align-items: center;
    justify-content: var(--prod-bottom-justify, space-between);
    column-gap: var(--gap-md);
    row-gap: var(--gap-xs);
    flex-wrap: wrap;

    &:focus-within {
      --opacity-bottom: 1;
    }
  }

  &__extra {
    margin-top: var(--gap-sm);
    display: flex;
    align-items: center;
    gap: var(--gap-sm);
  }

  &__vote {
    display: flex;
    gap: var(--gap-2xs);
  }

  &__favorite {
    .btn {
      &__icon {
        padding: var(--favorite-icon-padding, 0);
      }

      &__label {
        display: var(--favorite-label-display, block);
      }
    }
  }
}

.stats-item {
  display: flex;
  flex-direction: var(--stats-item-flex-direction, row);
  align-items: center;
  gap: var(--gap-xs);
  font-size: var(--font-size-sm);
  padding-top: calc(var(--gap-2xs) / 2);
  padding-bottom: calc(var(--gap-2xs) / 2);
  padding-left: var(--stats-item-padding-left, var(--gap-2xs));
  padding-right: var(--stats-item-padding-right, var(--gap-xs));
  border-radius: var(--radius-xs);
  background-color: hsla(var(--clr-white-h), var(--clr-white-s), var(--clr-white-l), 88%);
  max-width: max-content;
  margin: var(--stats-item-margin, 0);

  &__icon {
    .btn {
      display: block;
    }
  }

  &__label {
    line-height: var(--gap-md);
  }
}
