.form-field {
  border-radius: var(--radius-sm);
  border: 1px solid transparent;
  display: inline-flex;
  padding-top: 0;
  padding-bottom: 0;
  min-height: var(--control-height);
  transition: var(--duration-sm);

  &.disabled {
    cursor: not-allowed;

    button,
    label {
      pointer-events: none;
    }
  }

  &:has(.form-control:-webkit-autofill) {
    background-color: light-dark(rgb(232, 240, 254), rgba(70, 90, 126, 0.4));
  }

  &:has(.form-control:focus-visible) {
    border: 1px solid var(--clr-primary);
  }

  &--text,
  &--select {
    align-items: center;
    width: 100%;
  }

  &--select {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      pointer-events: none;
      right: var(--gap-sm);
      top: 50%;
      transform: translateY(-50%);
      width: var(--icon-sm);
      height: var(--icon-sm);
      background-image: url('../img/sprite.svg#arrow-down-16');
      background-size: contain;
      background-repeat: no-repeat;
      opacity: 0.32;
    }
  }

  &--textarea {
    width: 100%;
    align-items: start;

    .form-control {
      padding-top: var(--gap-xs);
      padding-bottom: var(--gap-xs);
    }

    .form-field__side {
      margin-top: calc(var(--gap-xs) - 1px);
    }
  }

  &--files {
    width: 100%;

    .file-attach {
      margin-right: calc((var(--icon-size-sm) / 2) * -1);
    }
  }

  &--primary {
    --color-h: var(--clr-primary-h);
    --color-s: var(--clr-primary-s);
    --color-l: var(--clr-primary-l);
    --color: hsl(var(--color-h), var(--color-s), var(--color-l));
  }

  &--s {
    --control-height: calc(var(--control-height-sm) - 2px);
    padding-left: var(--gap-2xs);
    padding-right: var(--gap-2xs);
    column-gap: var(--gap-xs);
    font-size: var(--font-size-sm);
  }

  &--m {
    --control-height: calc(var(--control-height-md) - 2px);
    padding-left: var(--gap-md);
    padding-right: var(--gap-md);
    column-gap: var(--gap-xs);
    font-size: var(--font-size-base);
  }

  &--1 {
    --placeholder-color: var(--clr-gray);
    --border-color: var(--color-border, var(--clr-border-input));
    --border-color-hover: var(--clr-border-input-hover);
    --border-color-focus: var(--clr-border-input-focus);
    background-color: var(--color-bg, var(--clr-white));
    transition: var(--duration-sm);
    border-color: var(--border-color);

    &:hover {
      border-color: var(--border-color-hover);
    }

    &.form-field--focus {
      border-color: var(--border-color-focus);

      .form-field__icon {
        color: var(--border-color-focus);
      }
    }

    &.disabled,
    &:has(input:disabled, select:disabled, textarea:disabled) {
      background-color: hsl(var(--clr-gray-h), var(--clr-gray-s), 98%);
      color: var(--clr-gray);

      &:hover {
        border-color: hsl(var(--clr-gray-h), var(--clr-gray-s), 98%);
      }
    }
  }

  &--2 {
    --placeholder-color: var(--clr-gray);
    background-color: var(--clr-bg-gray-lighten);
    transition: background-color .2s;

    &:hover {
      background-color: var(--clr-bg-gray-light);
    }

    &.form-field--focus {
      background-color: var(--clr-bg-gray-light);
    }

    &.disabled {
      background-color: hsl(216, 20%, 98%);

      &::placeholder {
        color: hsl(205, 13%, 82%);
      }

      svg {
        fill: hsl(205, 13%, 82%);
      }
    }
  }

  &--3 {
    --placeholder-color: var(--clr-placeholder);
    background-color: transparent;
    transition: background-color .2s;

    svg {
      fill: var(--clr-black);
    }
  }

  &__btn,
  &__attach {
    display: inline-block;
    vertical-align: middle;
    flex-shrink: 0;
    color: var(--placeholder-color);
  }

  &__btn,
  &__attach {
    padding: 0;
    border: none;
    background-color: transparent;
    cursor: pointer;

    &:hover {
      color: var(--color);
    }
  }
}

.form-icon,
.form-btn,
.form-text {
  display: block;
}

.form-btn {
  padding: 0;
  cursor: pointer;
  border: 0 none;
  background-color: transparent;
}

.form-control {
  flex-grow: 1;
  padding: 2px var(--gap-md);
  min-height: var(--control-height);
  margin-left: calc(var(--gap-md) * -1);
  margin-right: calc(var(--gap-md) * -1);

  &[type="password"] {
    &:not(:placeholder-shown) {
      font-weight: bold;
      letter-spacing: 4px;
    }
  }

  &[type="number"] {
    appearance: none;
    -moz-appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      appearance: none;
      margin: 0;
    }
  }

  &::placeholder {
    color: var(--placeholder-color);
  }

  &:disabled {
    background-color: transparent;
    cursor: inherit;
  }

  &:invalid {
    color: var(--placeholder-color);

    option:not(:disabled) {
      color: var(--clr-black);
    }
  }

  &--code {
    font-weight: bold;
    letter-spacing: 2px;
    text-align: center;
  }

  &--s {
    max-width: 8ch;
  }
}
