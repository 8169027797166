.footer {
  margin-top: var(--gap-3xl);
  padding-top: var(--gap-3xl);
  border-top: 1px solid var(--clr-border);
  line-height: 1.5;

  a {
    transition: color var(--duration-sm);

    &:hover {
      color: var(--clr-primary);
    }
  }

  &__main {
    @include media-up(md) {
      display: flex;
      gap: var(--gap-2xl);
    }
  }

  &__navs {
    @include media-down(md) {
      display: none;
    }

    @include media-up(md) {
      flex-grow: 1;
      display: flex;
      gap: var(--gap-xl);
      justify-content: space-between;
    }
  }

  &__info {
    @include media-up(md) {
      flex-basis: 172px;
      flex-shrink: 0;
    }

    @include media-up(lg) {
      flex-basis: 200px;
    }
  }

  &__soci {
    margin-top: var(--gap-3xl);
  }

  &__bottom {
    margin-top: var(--gap-3xl);
    background-color: #F6F6F7;
    padding: var(--gap-md) 0;
    color: var(--clr-gray);
    font-size: var(--font-size-xs);
  }
}

.f-title {
  font-weight: 500;
  margin-bottom: var(--gap-lg);
}

.f-nav {
  &__list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: grid;
    grid-template-columns: 100%;
    gap: var(--gap-xs);
  }

  &__link {
    display: block;
    padding: var(--gap-2xs) 0;
    font-size: var(--font-size-sm);
  }
}

.f-info {
  &__description {
    font-size: var(--font-size-sm);
    font-weight: 400;
    margin-bottom: var(--gap-lg);
  }

  &__main {
    display: flex;
    flex-wrap: wrap;
    gap: var(--gap-lg);

    @include media-up(md) {
      flex-direction: column;
    }
  }

  &__block {
    @include media-down(md) {
      flex-basis: 168px;
    }
  }

  &__value {
    font-weight: 500;

    @include media-down(lg) {
      font-size: var(--font-size-lg);
    }

    @include media-up(lg) {
      font-size: var(--font-size-xl);
    }
  }

  &__label {
    font-size: var(--font-size-sm);
    line-height: 1;
    margin-top: var(--gap-2xs);
  }
}

.soci-nav {
  &__list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    column-gap: var(--gap-3xl);
    row-gap: var(--gap-lg);
    flex-wrap: wrap;
  }

  &__link {
    display: flex;
    gap: var(--gap-xs);
    font-size: var(--font-size-sm);

    svg {
      flex-shrink: 0;
    }
  }
}

.nav-box {
  z-index: 10500;

  &::backdrop,
  & + .backdrop {
    z-index: 10450;

    @include media-down(lg) {
      height: calc(100dvh - 64px);
    }
  }

  &[open] {
    bottom: 100%;
    width: 100%;
    height: calc(100dvh - 64px);
    padding: var(--gap-lg) var(--gap-sm) var(--gap-lg) var(--gap-lg);
    background-color: var(--clr-white);
    left: 0;
    right: 0;
    scrollbar-gutter: stable;
    max-width: none;
    max-height: none;
    border: none;
    margin: 0;

    @include scrollbar-v;
  }

  &__close {
    position: sticky;
    top: var(--gap-2xs);
    display: flex;
    justify-content: flex-end;
    width: var(--control-height-sm);
    margin-left: auto;

    .btn {
      position: relative;
      margin-right: calc(var(--gap-2xs) * -1);

      &:focus-visible {
        outline: none;
      }
    }
  }

  &__list {
    list-style: none;
    margin: calc(var(--icon-xl) * -1) 0 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: var(--gap-lg);
  }

  &__link {
    font-weight: var(--font-weight-medium);
    display: flex;
    gap: var(--gap-lg);
    align-items: center;
    padding: var(--gap-xs) 0;
  }

  &__sublist {
    list-style: none;
    padding: 0;
    margin: var(--gap-xs) 0 var(--gap-sm);
    display: flex;
    flex-direction: column;
    gap: var(--gap-xs);
  }

  &__sublink {
    display: block;
    padding: var(--gap-2xs) 0;
  }
}
