.promo-teaser {
  background-color: var(--clr-bg-light);
  border-radius: var(--radius-md);
  overflow: hidden;
  max-width: max-content;

  &__title {
    margin: 0;
    font-weight: var(--font-weight-medium);
    font-size: var(--font-size-base);
    padding: var(--gap-md) var(--gap-lg);
  }

  picture {
    width: 100%;
  }
}
