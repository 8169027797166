.card {
  display: flex;
  flex-direction: column;
  gap: var(--gap);
  padding: var(--gap) 0;

  &--sm {
    --gap: var(--gap-xl);
    --title-font-size: var(--font-size-lg);
    --text-font-size: var(--font-size-sm);
  }

  &--md {
    --gap: var(--gap-2xl);
    --title-font-size: clamp(var(--font-size-lg), 4vw, var(--font-size-xl));
    --text-font-size: var(--font-size-base);
  }

  &__title {
    font-size: var(--title-font-size);
    font-weight: var(--font-weight-semibold);
  }

  &__text {
    font-size: var(--text-font-size);
    line-height: 160%;
  }
}
