*,
*::before,
*::after {
  box-sizing: border-box;
}

:root {
  scroll-behavior: smooth;
}

body {
  position: relative;
  margin: 0;

  input,
  button,
  textarea,
  select,
  optgroup {
    font-family: inherit;
    font-size: inherit;
    appearance: none;
    color: currentColor;
  }

  input,
  select,
  textarea {
    display: block;
    width: 100%;
    resize: none;
    border: none;
    background-color: transparent;

    &:focus {
      outline: none;
    }
  }
}

button {
  border: 0 none;
  background-color: transparent;
  cursor: pointer;
  padding: 0;
}

dialog::backdrop,
dialog + .backdrop {
  background-color: transparent;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

sup, sub {
  line-height: 0.5;
}
