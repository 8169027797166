.cart-head {
  @include media-down(md) {
    display: none;
  }

  &__col {
    font-size: var(--font-size-sm);

    &--sum {
      @at-root th#{&} {
        text-align: right;
      }
    }
  }
}

.cart-prod {
  &--inactive {
    --link-pointer-events: none;
    opacity: var(--opacity-inactive);
  }

  @include media-down(md) {
    display: flex;
    flex-direction: column;
    gap: var(--gap-sm);
    position: relative;
    padding-top: var(--gap-lg);
    padding-bottom: var(--gap-lg);

    &__col {
      &--main {
        margin-right: var(--icon-xl);
      }

      &--remove {
        position: absolute;
        top: var(--gap-lg);
        right: calc(clamp(var(--gap-md), 3.2vw, var(--gap-2xl)) * -1);
        height: var(--photo-md);
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }

    &:first-child {
      border-top: 1px solid var(--clr-border-input);
    }

    &:not(:last-child) {
      border-bottom: 1px solid var(--clr-border-input);
    }

    td {
      display: flex;
      padding-top: 0;
      padding-bottom: 0;
      border-bottom: 0;

      &:first-child,
      &:last-child,
      &:not(:first-child),
      &:not(:last-child) {
        padding-left: clamp(var(--gap-md), 3.2vw, var(--gap-2xl));
        padding-right: clamp(var(--gap-md), 3.2vw, var(--gap-2xl));
      }

      &[data-label] {
        display: grid;
        grid-template-columns: var(--photo-md) 1fr;
        align-items: center;
        gap: var(--gap-lg);
        line-height: 20px;

        &::before {
          content: attr(data-label);
          font-size: var(--font-size-sm);
        }
      }
    }
  }

  &__discount {
    font-size: var(--font-size-lg);
    font-weight: var(--font-weight-semibold);
  }

  &__prices {
    display: flex;
    row-gap: var(--gap-2xs);
    column-gap: var(--gap-sm);

    @include media-down(md) {
      align-items: center;
    }

    @include media-up(md) {
      flex-direction: column;
    }
  }

  &__price {
    &-new,
    &-old {
      white-space: nowrap;
    }

    &-new {
      font-size: var(--font-size-lg);
      font-weight: var(--font-weight-bold);
    }

    &-old {
      color: var(--clr-gray);
      text-decoration: line-through;
      font-size: var(--font-size-sm);
      font-weight: var(--font-weight-medium);
    }
  }

  &__col {
    &--sum {
      text-align: right;
    }
  }
}

.qty-box {
  display: inline-flex;
  align-items: center;
  gap: var(--gap-2xs);

  &__input {
    width: var(--icon-xl);
    text-align: center;
    padding: 0;
    -moz-appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

  }
}

.under-cart {
  &__wrap {
    display: grid;
    column-gap: clamp(var(--gap-lg), 3.2vw, var(--gap-3xl));
    row-gap: clamp(var(--gap-lg), 3.2vw, var(--gap-2xl));

    @include media-up(md) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include media-up(lg) {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &__gift {
    display: flex;
    flex-direction: column;
    gap: var(--gap-md);
  }

  &__gift,
  &__promo,
  &__sum {

    @include media-up(lg) {
      grid-row: 1/2;
    }
  }

  &__gift {
    @include media-up(md) {
      grid-row: 2/3;
      grid-column: 1/2;
    }
  }

  &__promo {
    @include media-up(md) {
      grid-row: 1/2;
      grid-column: 1/2;
    }

    @include media-up(lg) {
      grid-column: 2/3;
    }
  }

  &__sum {
    @include media-up(md) {
      grid-column: 2/3;
      grid-row: 1/4;
    }

    @include media-up(lg) {
      grid-column: 3/4;
    }
  }
}

.cart-gift {
  @include media-down(xs) {
    margin-left: calc(var(--gap-lg) * -1);
    margin-right: calc(var(--gap-lg) * -1);
  }

  &--start {
    --top-hover-color: var(--clr-white);
    --top-hover-bg-color: var(--clr-primary);
  }

  &__box {
    background-color: var(--clr-bg-light);
    border-radius: var(--radius-sm);
    overflow: hidden;
  }

  &__top,
  &__main,
  &__bottom {
    padding-left: var(--gap-lg);
    padding-right: var(--gap-lg);
  }

  &__top,
  &__bottom {
    padding-top: var(--gap-sm);
    padding-bottom: var(--gap-sm);
  }

  &__top {
    width: 100%;
    text-align: left;
    display: grid;
    grid-template-columns: var(--icon-xl) 1fr;
    align-items: center;
    gap: var(--gap-lg);
    transition: var(--duration-sm);

    &:hover {
      background-color: var(--top-hover-bg-color, inherit);
      color: var(--top-hover-color, inherit);
    }
  }

  &__label {
    margin: var(--gap-xs) 0;
    font-size: var(--font-size-sm);
  }

  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__item {
    padding: var(--gap-xs) 0;

    &:not(:last-child) {
      border-bottom: 1px solid var(--clr-border-input);
    }
  }

  &__more {
    @include media-down(xs) {
      padding-left: var(--gap-lg);
      padding-right: var(--gap-lg);
    }
  }
}

.cart-promo {
  background-color: var(--clr-bg-light);
  border-radius: var(--radius-sm);

  @include media-down(xs) {
    margin-left: calc(var(--gap-lg) * -1);
    margin-right: calc(var(--gap-lg) * -1);
  }

  &[open] {
    --marker-rotate: 180deg;
  }

  &__top {
    display: flex;
    align-items: center;
    gap: var(--gap-lg);
    cursor: pointer;
    padding: var(--gap-sm) var(--gap-lg);
    list-style: none;

    &::-webkit-details-marker,
    &::marker {
      display: none;
    }
  }

  &__title {
    flex-grow: 1;
    font-weight: var(--font-weight-semibold);
  }

  &__marker {
    transform: rotate(var(--marker-rotate));
  }

  &__main {
    display: flex;
    flex-direction: column;
    gap: var(--gap-sm);
    padding: var(--gap-xs) var(--gap-lg) var(--gap-sm);
  }

  &__list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: var(--gap-2xs);
    max-width: fit-content;
  }
}

.cart-sum {
  padding: var(--gap-lg);
  background-color: var(--clr-bg-light);
  border-radius: var(--radius-md);
  display: flex;
  flex-direction: column;
  gap: var(--gap-md);

  @include media-down(xs) {
    margin-left: calc(var(--gap-lg) * -1);
    margin-right: calc(var(--gap-lg) * -1);
  }

  &__list {
    margin: 0;
    display: grid;
    grid-template-columns: max-content 1fr;
    column-gap: var(--gap-md);
    row-gap: var(--gap-sm);
    align-items: baseline;
  }

  &__label {
    font-weight: var(--font-weight-medium);

    @include media-down(xs) {
      font-size: var(--font-size-sm);
    }
  }

  &__value {
    display: flex;
    justify-content: flex-end;
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-xl);
    margin: 0;

    @include media-down(xs) {
      font-size: var(--font-size-lg);
    }

    &--main {
      font-size: var(--font-size-2xl);

      @include media-down(xs) {
        font-size: var(--font-size-xl);
      }
    }
  }
}

.checkout {
  &__wrap {
    display: grid;
    column-gap: var(--gap-2xl);
    row-gap: var(--gap-2xl);
    align-items: start;

    @include media-up(md) {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &__main {

    @include media-up(md) {
      grid-column: 1/3;
    }
  }

  &__extra {
    display: flex;
    flex-direction: column;
    gap: var(--gap-md);

    @include media-up(md) {
      position: sticky;
      top: var(--gap-md);
      grid-column: 3/4;
    }
  }
}

.checkout-form {
  &__main {
    display: flex;
    flex-direction: column;
    gap: var(--gap-4xl);
  }

  &__title {
    font-size: var(--font-size-2xl);
    font-weight: var(--font-weight-semibold);
    line-height: 1;
    margin: 0 0 1em;
  }

  &__under-switch {
    margin-top: var(--gap-xs);
    margin-left: calc(var(--icon-lg) + var(--icon-2xl) + calc(var(--gap-sm) * 2));
  }

  &__bottom {
    margin-top: clamp(var(--gap-3xl), 6vw, var(--gap-5xl));
    padding-top: clamp(var(--gap-md), 6vw, var(--gap-xl));
    border-top: 1px solid var(--clr-border-input);
    display: flex;
    flex-direction: column;
    gap: clamp(var(--gap-md), 6vw, var(--gap-xl));
  }

  &__sum {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    column-gap: var(--gap-lg);
    row-gap: var(--gap-sm);
    flex-wrap: wrap;

    &-label {
      font-size: var(--font-size-sm);
      font-weight: var(--font-weight-semibold);
    }

    &-value {
      font-size: clamp(var(--font-size-2xl), 4vw, var(--font-size-4xl));
      font-weight: var(--font-weight-bold);
    }
  }
}

.pu-point {
  display: flex;
  flex-direction: column;
  gap: var(--gap-sm);

  &__main {
    display: flex;
    flex-direction: column;
    gap: var(--gap-2xs);

    p {
      margin: 0;

      strong {
        font-weight: var(--font-weight-semibold);
      }
    }
  }
}

.cart-parts {
  display: flex;
  flex-direction: column;
  gap: var(--gap-md);
  border: 1px solid var(--clr-border);
  border-radius: var(--radius-md);
  padding: var(--gap-xs);

  &__title {
    line-height: 100%;
    font-weight: var(--font-weight-medium);
  }

  &__row {
    display: flex;
    justify-content: space-between;
    gap: var(--gap-xs);
    flex-wrap: wrap;
  }

  &__value {
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-sm);

    &-kopecks {
      font-weight: var(--font-weight-medium);
      font-size: var(--font-size-2xs);
      color: var(--clr-gray);
    }
  }

  &__label {
    font-size: var(--font-size-xs);
  }
}

.checkout-box {
  background-color: var(--clr-bg-light);
  padding: clamp(var(--gap-lg), 6vw, var(--gap-4xl));
  text-align: center;

  @include media-down(sm) {
    margin-left: calc(var(--gap-lg) * -1);
    margin-right: calc(var(--gap-lg) * -1);
  }

  @include media-up(sm) {
    border-radius: var(--radius-sm);
  }

  &__wrap {
    max-width: 720px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: var(--gap-2xl);
  }

  &__title {
    font-weight: var(--font-weight-semibold);
    font-size: clamp(var(--font-size-xl), 3.2vw, var(--font-size-4xl));
  }

  &__main,
  &__bottom {
    display: flex;
    flex-direction: column;
    gap: var(--gap-xl)
  }

  &__label {
    margin-bottom: var(--gap-md);
  }

  &__cta {
    font-size: var(--font-size-sm);
  }

  &__numb {
    margin: var(--gap-2xs) 0;
  }

  &__lk-message {
    a {
      font-weight: var(--font-weight-semibold);
    }
  }

  &__phone {
    strong {
      font-weight: var(--font-weight-semibold);
      font-size: var(--font-size-xl);
    }
  }

  &__notice {
    padding: clamp(var(--gap-md), 6vw, var(--gap-xl)) clamp(var(--gap-xl), 6vw, var(--gap-4xl));
    background-color: var(--clr-bg-light2);
    border-radius: var(--radius-sm);
    max-width: max-content;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: var(--gap-xs);

    &-cover {
      max-width: max-content;
      margin: 0 auto;
      color: var(--clr-primary);
    }

    &-title {
      font-weight: var(--font-weight-semibold);
      font-size: var(--font-size-xl);
    }
  }
}

.code-item {
  --message-height: calc(var(--icon-sm) + var(--gap-2xs));
  display: block;
  position: relative;
  padding-bottom: var(--message-height);
  width: var(--width, max-content);
  margin: auto;

  &.clicked {
    --message-opacity: 1;
  }

  &::before {
    position: absolute;
    content: attr(data-clicked);
    bottom: 0;
    left: 0;
    width: max-content;
    opacity: var(--message-opacity, 0);
    font-size: var(--font-size-xs);
    line-height: var(--message-height);
    padding-left: calc(var(--icon-sm) + var(--gap-2xs));
    background-image: url('../img/sprite.svg#check-16');
    background-size: var(--icon-sm) var(--icon-sm);
    background-repeat: no-repeat;
    background-position: left 0 top 50%;
    transition: opacity var(--duration-sm);
  }

  &--my,
  &--promo {
    --h-gap: .4em;
    --input-width: 100px;
    --input-height: var(--control-height-xs);
    --code-font-size: var(--font-size-xs);
    --code-font-weight: var(--font-weight-medium);
  }

  &--promo {
    --main-width: calc(var(--input-width) + var(--icon-sm) + var(--gap-6xl));
  }

  &--cert,
  &--numb {
    --h-gap: 1.6em;
    --max-width: max-content;
    --main-width: max-content;
    --input-height: var(--control-height-lg);
    --margin: 0 auto;
    --text-align: center;
    --code-font-size: clamp(var(--font-size-xl), 4.8vw, var(--font-size-5xl));
    --code-font-weight: var(--font-weight-extrabold);
    --code-letter-spacing: .16em;
    --message-right: 0;
  }

  &--numb {
    --input-width: 7ch;
  }

  &__input,
  &__message {
    max-width: var(--max-width, none);
    margin: var(--margin, 0);
    text-align: var(--text-align, left);
  }

  &__message {
    opacity: 0;
    transition: opacity var(--duration-sm);

    &.is-show {
      opacity: 1;
    }
  }

  &__input {
    padding: 0;
    min-width: var(--input-width, max-content);
    height: var(--input-height);
    pointer-events: none;
    font-weight: var(--code-font-weight);
    font-size: var(--code-font-size);
    letter-spacing: var(--code-letter-spacing);
  }

  &__main {
    display: flex;
    align-items: center;
    background-color: var(--clr-white);
    border-radius: var(--radius-sm);
    padding: 0 var(--h-gap);
    cursor: pointer;
    width: var(--main-width);
    margin: var(--margin);

    .svg {
      margin-left: auto;
    }
  }

  &__message {
    position: absolute;
    bottom: 0;
    left: 0;
    right: var(--message-right, auto);
  }
}
