.best-prod {
  --gap: clamp(var(--gap-lg), 4vw, var(--gap-2xl));
  position: relative;

  &__wrap {
    position: relative;
    display: flex;
    align-items: center;
    gap: var(--gap);

    @include media-down(sm) {
      flex-direction: column;
    }
  }

  &__cover {
    position: relative;
    display: block;
    width: var(--cover-size);
    aspect-ratio: 1/1;
    flex-shrink: 0;
    @include img-center;
  }

  &__title {
    font-size: clamp(var(--font-size-xl), 3.2vw, var(--font-size-2xl));
    margin-bottom: var(--gap-xs);
    margin-right: var(--control-height-sm);

    a {
      text-decoration: none;
    }
  }
}
