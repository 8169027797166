.tooltip-wrapper {
  &--inline {
    display: inline-block;
    vertical-align: baseline;
  }
}

.tooltip {
  display: none;
  position: absolute;
  padding: var(--gap-xl);
  background-color: var(--clr-white);
  box-shadow: 0 6px 24px hsl(var(--clr-black-h) var(--clr-black-s) var(--clr-black-l) / .08);
  z-index: 4;
  width: min(460px, calc(100vw - calc(var(--gap-md) * 2)));

  &__arrow {
    position: absolute;
    content: '';
    width: var(--gap-md);
    height: var(--gap-xs);
    clip-path: polygon(50% 0, 100% 100%, 0 100%);
    background-color: var(--clr-white);
    margin-top: calc(var(--gap-2xs) * -1);
  }
}
