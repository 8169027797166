.offer-teaser {
  --cover-size: var(--photo-md);
  --gap-column-size: var(--gap-lg);
  --gap-row-size: var(--gap-sm);
  position: relative;
  display: grid;
  align-items: center;
  column-gap: var(--gap-column-size);
  row-gap: var(--gap-row-size);
  padding: var(--padding, var(--gap-xs) var(--gap-md));
  border-radius: var(--radius-sm);
  border: 1px solid var(--border-color, var(--clr-border-input));

  &--simple {
    --cover-size: var(--photo-sm);
    --border-color: transparent;
    --grid-template-columns-min: var(--icon-lg) var(--cover-size) 1fr;
    --grid-template-columns-max: var(--icon-lg) var(--cover-size) 1fr;
    --padding: var(--gap-2xs) 0;
  }

  @include media-down(xs) {
    grid-template-columns: var(--grid-template-columns-min, var(--cover-size) 1fr);
  }

  @include media-up(xs) {
    grid-template-columns: var(--grid-template-columns-max, var(--cover-size) 1fr auto);
  }

  &--highlighted {
    border-color: hsl(var(--clr-primary-h), var(--clr-primary-s), 88%);
    box-shadow: inset 0 0 0 1px hsl(var(--clr-primary-h), var(--clr-primary-s), 88%);
  }

  &__control {
    .radio {
      &::before {
        position: absolute;
        content: '';
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }
    }
  }

  &__cover {
    flex-basis: var(--cover-size);
    width: var(--cover-size);
    height: var(--cover-size);
    flex-shrink: 0;
    @include img-center;

    &--blank {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--clr-bg-light);
      border-radius: var(--radius-sm);
      color: var(--clr-white);

      svg {
        width: var(--icon-2xl);
        height: var(--icon-2xl);
      }
    }
  }

  &__name {
    flex-grow: 1;
    font-weight: var(--font-weight-medium);
    font-size: var(--font-size-sm);

    span {
      color: var(--clr-gray);
      font-weight: normal;
    }
  }

  &__description {
    font-style: normal;
    color: var(--clr-gray);
    border-bottom: 1px dashed;
  }

  &__cart {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: var(--gap-column-size);

    @include media-down(xs) {
      grid-column: 2/3;
    }
  }

  &__prices {
    text-align: right;
    white-space: nowrap;

    &-changes {
      display: flex;
      align-items: baseline;
      gap: var(--gap-xs);
      font-size: var(--font-size-sm);
      font-weight: var(--font-weight-semibold);
    }
  }

  &__price-discount {
    color: var(--clr-primary);
  }

  &__price-old {
    color: var(--clr-gray);
    text-decoration: line-through;
  }

  &__price-new {
    font-size: var(--font-size-lg);
    font-weight: var(--font-weight-bold);
  }

  &__btn {
    .btn {
      background-color: var(--clr-white);
    }
  }
}
