.prod-filter {
  @include media-down(md) {
    border: 1px solid var(--clr-border-input);
    border-radius: var(--radius-sm);
    --display: none;
    --rotate-marker: 0deg;

    &.is-open {
      --display: block;
      --color-marker: var(--clr-primary);
    }

    &__top,
    &__main {
      padding-left: var(--gap-md);
      padding-right: var(--gap-md);
    }

    &__top {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      gap: var(--gap-md);
      padding-top: var(--gap-sm);
      padding-bottom: var(--gap-sm);
      cursor: pointer;
    }

    &__marker {
      color: var(--color-marker, inherit)
    }

    &__main {
      display: var(--display);
      padding-top: var(--gap-2xs);
      padding-bottom: var(--gap-lg);
    }

    &__label {
      font-weight: var(--font-weight-medium);
      font-size: var(--font-size-sm);
    }

    &__qty {
      --qty-size: var(--icon-md);
      vertical-align: baseline;
      line-height: inherit;
      font-size: var(--font-size-xs);
      font-weight: var(--font-weight-semibold);
      min-width: var(--qty-size);
      height: var(--qty-size);
      display: inline-flex;
      justify-content: center;
      align-items: center;
      border-radius: calc(var(--qty-size) / 2);
      padding: 0 var(--gap-2xs);
      background-color: var(--clr-primary);
      color: var(--clr-white);
    }
  }

  @include media-up(md) {
    position: sticky;
    top: var(--gap-xs);

    &__top {
      display: none;
    }
  }

  &--loading {
    cursor: wait;

    > * {
      pointer-events: none;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: var(--gap-xs);
  }

  &__reset-btn {
    margin-top: var(--gap-sm);
  }
}

.filt-box {
  &__top,
  &__bottom {
    @include media-down(xl) {
      padding-left: var(--gap-sm);
      padding-right: var(--gap-sm);
      margin-left: calc(var(--gap-sm) * -1);
      margin-right: calc(var(--gap-sm) * -1);
    }

    @include media-up(xl) {
      padding-left: var(--gap-md);
      padding-right: var(--gap-md);
      margin-left: calc(var(--gap-md) * -1);
      margin-right: calc(var(--gap-md) * -1);
    }
  }

  &__top {
    padding-bottom: var(--gap-xs);
    border-bottom: 1px solid var(--clr-border-input);
    margin-bottom: var(--gap-md);
  }

  &__bottom {
    margin-top: var(--gap-md);
    padding-top: var(--gap-md);
    border-top: 1px solid var(--clr-border-input);
  }

  &__list {
    max-height: 400px;
    @include scrollbar-v;
  }

  &__item {
    padding-left: var(--gap-2xs);
    margin-left: 1px;
  }

  &__more-link {
    text-align: center;
    margin-top: var(--gap-xs);
  }

  &__prices-slider {
    padding-left: var(--gap-xs);
    padding-right: var(--gap-xs);
    margin-left: calc(var(--gap-xs) * -1);
    margin-right: calc(var(--gap-xs) * -1);
  }

  &__prices-range {
    margin-top: var(--gap-lg);
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: var(--gap-xs);
    color: var(--clr-gray);
  }
}
