.our-select {
  position: relative;

  &__dialog {
    border: 1px solid var(--clr-border);
    padding: var(--gap-2xs) 0;
    min-width: 200px;
    margin-top: var(--gap-2xs);

    &[open] {
      z-index: 8;
      left: calc(var(--gap-md) * -1);
      right: auto;
    }
  }

  &__list {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  &__btn {
    display: block;
    width: 100%;
    text-align: left;
    font-size: var(--font-size-sm);
    font-weight: var(--font-weight-medium);
    line-height: var(--gap-md);
    padding: var(--gap-xs) var(--gap-md);
    transition: var(--duration-sm);

    &:hover,
    &.is-selected {
      background-color: var(--clr-bg-light2);
    }
  }
}
