.fill-icon {
  &.is-filled {
    --opacity-stroke: 0;
    --opacity-fill: 1;
  }
}

.icon {
  display: grid;
  grid-template-columns: 100%;

  svg {
    grid-column: 1/2;
    grid-row: 1/2;

    &:nth-child(1) {
      opacity: var(--opacity-stroke, 1);
    }

    &:nth-child(2) {
      opacity: var(--opacity-fill, 0);
    }
  }
}
