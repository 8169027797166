.feel-box {
  &__head {
    font-weight: var(--font-weight-semibold);
    margin-bottom: var(--gap-xl);
  }

  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: var(--gap-xl);
  }
}

.feel-item {
  display: flex;
  align-items: center;
  gap: var(--gap-sm);
  font-size: var(--font-size-sm);

  &__head {
    flex-basis: calc(var(--control-height-sm) + var(--gap-sm) + 48px);
    display: flex;
    gap: var(--gap-sm);
    align-items: center;
  }

  &__body {
    flex-grow: 1;
    display: flex;
    align-items: baseline;
    gap: var(--gap-xs);
  }

  &__value {
    min-width: 2ch;
    text-align: right;
  }

  &__bar {
    position: relative;
    flex-grow: 1;
    height: 4px;
    background-color: var(--clr-border-input);

    &::after {
      position: absolute;
      content: '';
      left: 0;
      top: 0;
      bottom: 0;
      background-color: var(--clr-primary);
      width: calc(var(--feel-value) / var(--feel-sum) * 100%);
    }
  }
}