.dropdown {
  position: relative;

  &.is-open {
    .dropdown-toggle {
      svg {
        transform: rotate(180deg);
      }
    }

    .dropdown-menu {
      display: block;
      z-index: 4;
    }
  }
}

//TO DO: fix position from right
.dropdown-menu {
  display: none;
  position: absolute;
  right: 0;
  margin: 0;
  padding: var(--gap-2xs) 0;
  list-style: none;
  background-color: var(--clr-white);
  border: 1px solid var(--clr-border-input);
  border-bottom-left-radius: var(--radius-sm);
  border-bottom-right-radius: var(--radius-sm);
}

.dropdown-item {
  padding: var(--gap-xs) var(--gap-md);
  display: block;
  width: 100%;
  text-align: left;
  transition: var(--duration-sm);
  white-space: nowrap;

  &.is-selected,
  &:hover {
    background-color: var(--clr-bg-light);
  }
}
