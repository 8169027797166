.review-teas {
  --line-height: var(--gap-lg);

  &__top {
    &:hover {
      --color: var(--clr-primary);
    }
  }

  &__cover {
    display: block;
    height: 180px;
  }

  &__title,
  &__text,
  &__author,
  &__more {
    line-height: var(--line-height);
  }

  &__title {
    display: block;
    font-weight: 500;
    min-height: calc(var(--line-height) * 2);
    margin-top: var(--gap-xl);
    margin-bottom: var(--line-height);
    color: var(--color, var(--clr-black));
    transition: var(--duration-sm);
  }

  &__text {
    --lines-qty: 8;
    font-size: var(--font-size-sm);
    display: block;
    display: -webkit-box;
    overflow: hidden;
    max-height: calc(var(--line-height) * var(--lines-qty));
    text-overflow: ellipsis;
    -webkit-line-clamp: var(--lines-qty);
    -webkit-box-orient: vertical;
  }

  &__author {
    font-size: var(--font-size-sm);
    font-weight: bold;
    margin-top: var(--line-height);
  }

  &__more {
    margin-top: var(--line-height);
  }
}
