.payment {
  display: flex;
  flex-direction: column;
  gap: clamp(var(--gap-lg), 4vw, var(--gap-2xl));

  &__list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    gap: clamp(var(--gap-lg), 4vw, var(--gap-3xl));
  }
}

.payment-method {
  --border-color: var(--clr-border-input);
  border: 1px solid var(--border-color);
  padding: clamp(var(--gap-md), 4vw, var(--gap-2xl));
  border-radius: var(--radius-sm);
  display: flex;
  flex-direction: column;
  gap: clamp(var(--gap-md), 4vw, var(--gap-2xl));
  transition: border-color var(--duration-sm);

  &:has(input:checked) {
    --border-color: var(--clr-primary);
    --label-color: var(--clr-primary);
  }

  &__top {
    position: relative;
    margin-bottom: var(--gap-sm);
    display: flex;
    gap: var(--gap-md);
  }

  &__cover {
    img {
      border-radius: var(--radius-md);
    }
  }

  &__radio {
    margin-left: auto;

    label {
      position: unset;

      &::before {
        position: absolute;
        content: '';
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }
    }
  }

  &__title {
    font-weight: var(--font-weight-semibold);
    font-size: var(--font-size-lg);
    margin: 0;
  }
}