html,
body {
  scrollbar-gutter: stable;
}

body {
  font-family: var(--font-family-base);
  font-size: var(--font-size-base);

  &.noscroll {
    height: 100dvh;
    overflow: hidden;
  }
}

a {
  text-decoration: none;
  color: var(--clr-black);
}

img {
  display: block;
  max-width: 100%;
  height: auto;

  &.img-center {
    margin: 0 auto;
  }

  &.img-bordered {
    border-radius: var(--radius-xl);
  }
}

svg {
  display: block;
  fill: currentColor;
}

* {
  &:focus-visible {
    outline-color: var(--clr-primary);
  }
}

.main-wrap {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.d-list {
  display: grid;
  grid-template-columns: max-content 1fr;
  column-gap: var(--gap-md);
  row-gap: var(--gap-2xs);
  margin: 0;

  dt, dd {
    margin: 0;
  }
}

.link {
  position: relative;
  text-decoration: underline;
  transition: var(--duration-sm);

  &--weak {
    text-decoration: none;

    &:hover {
      color: var(--clr-primary);
    }
  }

  &--strong {
    font-weight: var(--font-weight-bold);
  }

  &--semibold {
    font-weight: var(--font-weight-semibold);
  }

  &--dashed {
    text-decoration: none;

    &::after {
      content: '';
      position: absolute;
      bottom: -1px;
      left: 0;
      right: 0;
      height: 1px;
      background-image: linear-gradient(90deg, currentColor, currentColor 75%, transparent 75%, transparent 100%);
      background-size: 8px 1px, 100% 1px;
    }
  }

  &--gray {
    color: var(--clr-gray);

    &:hover {
      color: var(--clr-black);
    }
  }

  &--primary {
    color: var(--clr-primary);

    &:hover {
      color: var(--clr-black);
    }
  }

  &--light {
    --text-link-color: hsl(var(--clr-black-hsl) / 40%);
    text-decoration: none;
    position: relative;
    border-bottom: 1px solid var(--text-link-color);

    &:hover {
      --text-link-color: hsl(var(--clr-black-hsl) / 80%);
    }
  }

  &--out {
    padding-right: calc(var(--icon-sm) + var(--gap-2xs));
    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      width: var(--icon-sm);
      height: var(--icon-sm);
      background-image: url('../img/sprite.svg#out-url-16');
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
}

.container {
  --max-width: var(--container-lg);
  max-width: var(--max-width);
  margin: 0 auto;
  padding: 0 var(--gap-lg);

  &--md {
    --max-width: var(--container-md);
  }
}

.line {
  display: flex;
  flex-wrap: var(--flex-wrap, wrap);
  column-gap: var(--gap, var(--gap-md));
  row-gap: calc(var(--gap, var(--gap-md)) / 1.5);

  &--nowrap {
    --flex-wrap: nowrap;
  }

  &--gap-xs {
    --gap: var(--gap-xs);
  }

  &--gap-xl {
    --gap: var(--gap-xl);
  }

  &--gap-2xl {
    --gap: var(--gap-2xl);
  }

  &--jcsb {
    justify-content: space-between;
  }

  &--jcfe {
    justify-content: flex-end;
  }

  &--jcc {
    justify-content: center;
  }

  &--aic {
    align-items: center;
  }

  &__item {
    &--mla {
      margin-left: auto;
    }

    &--grow {
      flex-grow: 1;
    }

    &--bordered {
      border-radius: var(--radius-sm);
      border: 1px solid var(--clr-border-input);
      padding: var(--gap-2xs);
    }
  }
}

.text {
  font-size: var(--font-size, var(--font-size-sm));
  line-height: var(--line-height, 160%);

  &--xs {
    --font-size: var(--font-size-xs);
    --line-height: 120%;
  }

  &--sm {
    --font-size: var(--font-size-sm);
    --line-height: 120%;
  }

  &--md {
    font-size: var(--font-size-base);
  }

  &--lg {
    font-size: clamp(var(--font-size-base), 2.4vw, var(--font-size-lg));
  }

  &--xl {
    font-size: clamp(var(--font-size-lg), 2.8vw, var(--font-size-xl));
  }

  &--placeholder {
    color: var(--clr-placeholder);
  }

  &--center {
    text-align: center;
  }

  &--right {
    text-align: right;
  }

  &--gap-top {
    margin-top: 1.2em;
  }

  &--gap-bottom {
    margin-bottom: 1.2em;
  }

  &--error {
    display: none;
    font-weight: var(--font-weight-medium);
    color: var(--clr-error);
  }

  a {
    text-decoration: underline;
    color: inherit;
  }

  strong {
    font-weight: var(--font-weight-semibold);
  }

  p, ul, ol {
    margin: 0.8em 0;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  ol,
  ul {
    padding-left: var(--gap-2xl);

    li {
      &:not(:last-child) {
        margin-bottom: .4em;
      }
    }
  }

  h2, h3, h4, h5 {
    margin-top: 2em;
    margin-bottom: .8em;
    font-weight: var(--font-weight-semibold);
  }

  h2 {
    font-size: 1.5em;
  }
}

.date {
  font-size: var(--font-size);
  color: var(--clr-gray);

  &--sm {
    --font-size: var(--font-size-xs);
  }
}

.visually-hidden {
  @include visually-hidden;
}

.is-dragging,
.is-dragging * {
  cursor: grabbing;
}
