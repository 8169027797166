#pvz_map_container,
#map_content_block {
  max-height: unset !important;
}

#pvz_map_container {
  height: auto !important;
  width: auto !important;
  margin: 0 !important;
  inset: 4vw !important;
  top: 4vw;
  bottom: 4vw;
  left: 4vw;
  right: 4vw;
  border-radius: var(--radius-md);

  #map_filter_top_block {
    a {
      position: absolute;
      top: var(--gap-2xs) !important;
      right: var(--gap-2xs) !important;
      width: var(--control-height-sm);
      height: var(--control-height-sm);
      overflow: hidden;
      text-indent: -9999px;
      background-color: transparent;
      cursor: pointer;
      border: 0;
      padding: 0 !important;
      background-image: url('../img/sprite.svg#cross-24');
      background-size: 24px 24px;
      background-repeat: no-repeat;
      background-position: center;
      opacity: .32;
      transition: opacity var(--duration-sm);

      &:hover {
        opacity: .48;
      }
    }
  }

  #map_filter_address_block {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    column-gap: var(--gap-sm);
    row-gap: var(--gap-xs);
    padding: var(--gap-xs) var(--gap-md) !important;

    input {
      margin: 0;
    }

    input:not([type]) {
      border: 1px solid var(--clr-border-input);
      border-radius: var(--radius-sm);
      min-height: var(--control-height-md);
      padding: 0 var(--gap-md);
      max-width: 16rem !important;
      min-width: 12rem !important;

      &::placeholder {
        color: var(--clr-placeholder);
      }
    }

    @include media-down(sm) {

      #searchStreet,
      #searchHouse {
        display: none;
      }
    }

    input[type="button"] {
      width: unset;
      min-height: var(--control-height-md);
      border-radius: var(--radius-sm);
      cursor: pointer;
      background-color: var(--clr-primary);
      color: var(--clr-white);
      border: 0;
      padding: var(--gap-2xs) var(--gap-md);
    }

    .autocomplete-items {
      margin-top: var(--gap-2xl);
    }
  }

  #map_load_block,
  #map_error_block {
    position: absolute;
    top: 43vh;
    left: 0;
    right: 0;
  }

  .leaflet-popup-content-wrapper {
    border-radius: var(--radius-md);
  }

  .leaflet-popup-content {
    margin: var(--gap-md);
    font-size: var(--font-size-xs);

    input[type="button"] {
      cursor: pointer;
      background-color: var(--clr-primary);
      color: var(--clr-white);
      font-size: var(--font-size-xs);
      border-radius: var(--radius-sm);
      padding: var(--gap-xs);
    }

    img {
      display: inline-block;
    }

    > div {
      > div:first-child {
        float: left;
        max-width: calc(100% - 100px);
      }
    }
  }
}
