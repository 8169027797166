.info-cover {
  position: relative;
  margin: 0 auto;

  @include media-down(sm) {
    max-width: 160px;
    height: 80px;
  }

  @include media-up(sm) {
    max-width: 290px;
    height: 190px;
  }

  &--logo {
    @include img-center;
  }
}
