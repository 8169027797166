.cert-teaser {
  display: flex;
  flex-direction: column;
  gap: var(--gap-sm);

  @media (hover: hover) {
    --opacity-extra: 0;

    &:hover {
      --opacity-extra: 1;
    }
  }

  &__main {
    display: flex;
    flex-direction: column;
    gap: var(--gap-sm);
  }

  &__cover {
    img {
      border-radius: var(--radius-xl);
    }
  }

  &__text {
    font-weight: var(--font-weight-medium);
  }

  &__price {
    font-size: var(--font-size-lg);
    font-weight: var(--font-weight-bold);
  }

  &__extra {
    transition: var(--duration-sm);
    opacity: var(--opacity-extra);

    &:focus-within {
      --opacity-extra: 1;
    }
  }
}